<div id="broker-component" *ngIf="this.isBrokerFlow">
    <p *ngIf="this.brokerName !== undefined">
      {{'broker-display-brokered-by' | transloco : {brokerName : brokerName} }}
      <a (click)="toggleEditBroker()">&#9998;</a>
    </p>
    <div class="update-broker-div">
        <div *ngIf="this.showBrokerUpdateInput">
            <ng-select [(ngModel)]="selectedBrokerEmail"
            [items]="brokerEmails"
            [searchFn]="emailFilterFunction"
            placeholder=""
            class="custom-pet-info form-control"
            [class.invalid-broker-div]="this.submittedIncomplete"
            [clearable]="false"
            (change)="this.brokerEmailChangeListener($event)">
            </ng-select>

            <p *ngIf="!this.showDispatchControl()" [innerHtml]="'broker-display-email-input-label' | transloco"></p>
            <p *ngIf="this.showDispatchControl()" [innerHtml]="'broker-display-email-input-label-with-dispatch-control-shown' | transloco"></p>
        </div>


        <div *ngIf="this.showDispatchControl()">
            <ng-select
            bindLabel="name"
            bindValue="id"
            placeholder=""
            class="custom-pet-info form-control"
            [clearable]="false" labelForId="cat-breeds"
            [(ngModel)]="this.selectedDispatchMode"
            (change)="this.dispatchControlChangeListener($event)">
            <ng-option *ngFor="let option of dispatchOptions" [value]="option.id">
                {{option.name()}}
            </ng-option>
            </ng-select>

            <p [innerHtml]="'broker-display-dispatch-control-input-label' | transloco"></p>
        </div>

        <!--
            <button [innerHtml]="'broker-display-update-broker-button-text' | transloco" (click)="setBrokerEmailAndDispatchControlIfPresent(selectedBrokerEmail)"></button>
        -->

    </div>
</div>

<div *ngIf="!this.isBrokerFlow && dataSharingRequestedWithCalingoFlavor">
    <p [innerHtml]="'broker-display-brokered-by-no-edit' | transloco : {brokerName : brokerName}" *ngIf="this.brokerName !== undefined"></p>
</div>
