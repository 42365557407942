import { inject, Injectable } from "@angular/core";
import { Translation, TranslocoLoader } from "@ngneat/transloco";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  private static translationBase: string = '/assets/i18n/'
  private getTranslationBase(): string {
    return TranslocoHttpLoader.translationBase
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(TranslocoHttpLoader.translationBase + `${lang}.json`);
  }

  getWhiteLabelTranslation(lang: string, translationLocation: string) {
    return this.http.get<Translation>(`${translationLocation}${lang}.json`);
  }

  static setTranslationPath(translationLocation: string) {
    TranslocoHttpLoader.translationBase = translationLocation
  }
}
