import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AlertModel {
  title?: string;
  message?: string;
}

@Component({
  selector: 'app-alert',
  template: `
  <div class="modal-content-custom">
    <div class="modal-header-custom">
      <h3>{{title}}</h3>
    </div>
    <div class="modal-body-custom">
      <p>{{message}}</p>
    </div>
    <div class="modal-footer-custom">
      <button (click)="close()">OK</button>
    </div>
  </div>
  `,
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends SimpleModalComponent<AlertModel, null> implements AlertModel {
  title: string | undefined;
  message: string | undefined;
  constructor() {
    super();
  }
}
