import {ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import {PhoneNumberUtil, PhoneNumber} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(control: AbstractControl): ValidationErrors | null {
  let validNumber = false;
  try {
    const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
      control.value, "CH"
    );
    validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
  } catch (e) {
  }

  return validNumber ? null : {'wrongNumber': {value: control.value}};
}
