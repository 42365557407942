<div id="main-banner" class="text-center">
  <div class="top-wrapper">
    <div class="top-left">
      <div id="calingo-logo"></div>
    </div>
    <div class="top-right">
      <app-phone></app-phone>
      <app-language-choice></app-language-choice>
    </div>
  </div>
  <app-heroes></app-heroes>

  <app-broker-display></app-broker-display>

  <app-stepper></app-stepper>
</div>
<router-outlet (deactivate)="onDeactivate()"></router-outlet>
