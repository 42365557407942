import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { State, PremiumCalculationInput, PremiumCalculationResponse, GetStateRequest, GetBrokerRequest, SendOfferRequest, GetStateFromMfvOfferRequest } from './state';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

const BASE_URL = environment.apiBaseUrl
const CLOSING_API_URL = BASE_URL + '/close'
const SAVNG_API_URL = BASE_URL + '/save'
const PREMIUM_API_URL = BASE_URL + '/calculate-premium'
const STATE_API_URL = BASE_URL + '/get-state'
const GET_BROKER_API_URL = BASE_URL + '/get-broker'
const SEND_OFFER_API_URL = BASE_URL + '/send-offer'
const SEND_PDF_OFFER_TO_CUSTOMER_API_URL = BASE_URL + '/send-pdf-offer-customer'
const SEND_PDF_OFFER_TO_BROKER_API_URL = BASE_URL + '/send-pdf-offer-broker'
const SEND_PDF_OFFER_DYNAMIC_DISPATCH_CONTROL_API_URL = BASE_URL + '/send-mfv-offer-dynamic-dispatch'
const GET_STATE_FROM_MFV_API_URL = BASE_URL + '/get-mfv-offer'


@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(
    private httpClient: HttpClient
  ) { }


  // TODO: proper return type
  async close(state: State): Promise<any> {
    console.warn('posting state')
    const res = await lastValueFrom(this.httpClient.post<any>(CLOSING_API_URL, state))
    return res
  }

  async save(state: State): Promise<any> {
    console.warn('http service saving state')
    const res = await lastValueFrom(this.httpClient.post<any>(SAVNG_API_URL, state))
    return res
  }

  async calculatePremium(premiumCalculationInput: PremiumCalculationInput): Promise<any> {
    console.warn('calculating premium')
    const res = await lastValueFrom(this.httpClient.post<any>(PREMIUM_API_URL, premiumCalculationInput))
    return res
  }

  async getState(sessionId: string): Promise<any> {
    let getStateRequest: GetStateRequest = {sessionId: sessionId}
    console.warn('getting state')
    const res = await lastValueFrom(this.httpClient.post<any>(STATE_API_URL, getStateRequest))
    console.warn('state that was fetched from backend via sessionID', res)
    return res
  }

  async getStateFromMfvOffer(contractId: string, contractToken: string): Promise<any> {
    let getStateFromMfvOfferRequest: GetStateFromMfvOfferRequest = {contractId: contractId, contractToken: contractToken}
    console.warn('getting state')
    const res = await lastValueFrom(this.httpClient.post<any>(GET_STATE_FROM_MFV_API_URL, getStateFromMfvOfferRequest))
    console.warn('state that was fetched from backend via contractID and contract token', res)
    return res
  }

  async getBroker(getBrokerRequest: GetBrokerRequest): Promise<any> {
    console.warn('getting broker')
    const res = await lastValueFrom(this.httpClient.post<any>(GET_BROKER_API_URL, getBrokerRequest))
    return res
  }

  async sendOffer(sessionId: string, brokerCc?: boolean): Promise<any> {
    let sendOfferRequest: SendOfferRequest = {sessionId: sessionId, brokerCc: brokerCc}
    console.warn('getting state')
    const res = await lastValueFrom(this.httpClient.post<any>(SEND_OFFER_API_URL, sendOfferRequest))
    return res
  }

  async sendPdfOfferToCustomer(state: State) {
    console.warn('http service sending pdf offer to customer')
    const res = await lastValueFrom(this.httpClient.post<any>(SEND_PDF_OFFER_TO_CUSTOMER_API_URL, state))
    return res
  }

  async sendPdfOfferToBroker(state: State) {
    console.warn('http service sending pdf offer to broker')
    const res = await lastValueFrom(this.httpClient.post<any>(SEND_PDF_OFFER_TO_BROKER_API_URL, state))
    return res
  }

  async sendPdfOfferDynamicDispatchControl(state: State) {
    console.warn('http service sending pdf offer to broker')
    const res = await lastValueFrom(this.httpClient.post<any>(SEND_PDF_OFFER_DYNAMIC_DISPATCH_CONTROL_API_URL, state))
    return res
  }

}
