import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, DOCUMENT, formatDate } from '@angular/common';


import { StateService } from '../state.service';
import { State } from '../state';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { SegmentService } from 'ngx-segment-analytics';
// import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../http-service.service';
import { createStandardQueryParamsForUrlPrefix, fetchQueryParameterFromWindowLocation, getUrlWithHashLocation, languageCodeFromLocaleId, ngbDateToIso, optionalStartDateValidatorFn, parentBirthdayValidatorFn } from '../utils';
import zipPlaceMap from '../../assets/zipcode-place.json';
import { DocumentsService } from '../documents.service';
import { LoadingScreenService } from '../loading-screen.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {environment} from "../../environments/environment";
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { SuccessPageService } from '../success-page-service.service';
import {PhoneNumberValidator} from "../validators/phone-number-validator";
import {PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";

@Component({
  selector: 'app-parent-info',
  templateUrl: './parent-info.component.html',
  styleUrls: ['./parent-info.component.scss']
})
export class ParentInfoComponent implements OnInit {
  // customCompanySuccessPageUrl?: string;

  phoneNumberUtil = PhoneNumberUtil.getInstance();

  constructor(
    private stateService: StateService,
    // private segment: SegmentService,
    private router: Router,
    // private gaService: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document,
    private datePipe: DatePipe,
    private httpService: HttpService,
    private documentsService: DocumentsService,
    private loadingScreenService: LoadingScreenService,
    private gtmService: GoogleTagManagerService,
    private successPageService: SuccessPageService,
  ) { }

  ngOnInit(): void {
    this.stateService.getIsInitialized().then(() => {
      console.warn('parent info component checking if state is ok')
      if (!this.stateService.step1Complete()) {
        this.router.navigate(['/pet-info'], { queryParamsHandling: 'merge' });
        return
      }
      if (!this.stateService.step2Complete()) {
        this.router.navigate(['/coverage-info'], { queryParamsHandling: 'merge' });
        return
      }
    })
      .then(() => {
        // as we checked previously that all fields are set, we can do some Rambo casting here
        let initState = this.getState()
        this.petName = initState.petName as string
        this.species = initState.species as string
        this.firstName = initState.firstName as string
        this.email = initState.email as string
        this.coverageLevel = initState.coverageLevel as string
        this.startDate = initState.startDate
        this.isMonthlyPayment = !initState.paymentFrequency || initState.paymentFrequency === 'm'

        this.parentInfoForm.get('firstName')?.setValue(initState.firstName)
        this.parentInfoForm.get('email')?.setValue(initState.email)
        if (initState.paymentFrequency !== undefined && initState.paymentFrequency !== null && initState.paymentFrequency !== '') {
          this.parentInfoForm.get('paymentFrequency')?.setValue(initState.paymentFrequency)
        } else {
          this.parentInfoForm.get('paymentFrequency')?.setValue('m')
        }
        this.parentInfoForm.get('parentGender')?.setValue(initState.parentGender)
        this.parentInfoForm.get('lastName')?.setValue(initState.lastName)
        this.parentInfoForm.get('parentBirthdate')?.setValue(initState.parentBirthdate)
        this.parentInfoForm.get('street')?.setValue(initState.street)
        this.parentInfoForm.get('number')?.setValue(initState.number)
        this.parentInfoForm.get('zip')?.setValue(initState.zip)
        this.parentInfoForm.get('city')?.setValue(initState.city)
        this.parentInfoForm.get('phoneNumber')?.setValue(initState.phoneNumber)


        if (this.startDate !== undefined) {
          let sd = new Date(this.startDate)
          if (sd.getTime() - Date.now() > 1000 * 3600 * 24) {
            this.startTomorrow = false
          }
        }

        if (initState.newsletterConsentGiven !== undefined) {
          this.newsletterConsent = initState.newsletterConsentGiven
        }


        this.stateService.fetchPremiumCurrentTotal(this.coverageLevel, {})


      }).then(() => {
      this.loadingScreenService.hideLoadingScreen()
    })

    this.stateService.isBrokerFlowObs.subscribe(
      value => {
        this.isBrokerFlow = value
      }
    )

  }

  closingWaitingMilis = 5000

  getState(): State {
    return this.stateService.getSnapshot()
  }

  showB2COfferButton(): boolean {
    return this.stateService.getWhitelabellingUIControllerSnapshot().showB2COfferButton === true && !this.isBrokerFlow
  }

  showDynamicDispatchControlOfferButton(): boolean {
    return this.stateService.getWhitelabellingUIControllerSnapshot().showDispatchControl === true && this.isBrokerFlow
  }

  showRiskCarrierFooter(): boolean {
    return this.stateService.getWhitelabellingUIControllerSnapshot().showRiskCarrierFooter === true
  }

  parentInfoForm = new FormGroup({
    parentGender: new FormControl('', [Validators.required]),
    firstName: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    lastName: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    parentBirthdate: new FormControl('', [Validators.required, parentBirthdayValidatorFn]),
    street: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    number: new FormControl('', {updateOn: 'blur'}), // there seem to be adresses in rural area without number, so no validators here for now
    zip: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    city: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    phoneNumber: new FormControl('', {updateOn: 'blur', validators: [Validators.required, PhoneNumberValidator]}),
    email: new FormControl('', {updateOn: 'blur', validators: [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]}),
  });


  // Dummy initialization, will be set in ngOnInit
  petName: string = '';
  species: string = '';
  firstName: string = '';
  lastName: string = '';
  parentBirthdate: string = ''
  email: string = '';
  coverageLevel: string = '';
  startDate: string|undefined;
  startTomorrow: boolean = true
  isMonthlyPayment: boolean = true

  newsletterConsent: boolean = false


  track(itemText: string, formItem: string, value: string, location?: string): void {
    if (value !== null && value !== undefined && value !== '') {
      // this.segment.track('Personal Info Updated', {path: '/closing', item_text: itemText, form_item: formItem, value: value, location: location || null})

      /*
      this.gaService.gtag('event', 'personal_info_updated', {
        'item_text': itemText,
        'form_item': formItem,
        'value': value,
        'location': location || null
      })
      */
    }
  }

  blur(target: EventTarget | null) {
    if (target !== null) {
      (target as HTMLInputElement).blur()
    }
  }

  submittedIncomplete = false
  isBrokerFlow: boolean = false
  showSendOfferButtons: boolean = false

  toggleShowSendOfferButtons() {
    this.showSendOfferButtons = !this.showSendOfferButtons
  }

  zipPlaceMapLocal = new Map<string,string>(Object.entries(zipPlaceMap));

  // see https://medium.com/javascript-everyday/how-to-scroll-to-first-invalid-control-once-a-form-has-been-submitted-eb47d9fbc6e
  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.document.querySelector(
      "form .ng-invalid"
    );

    if (firstInvalidControl !== null && firstInvalidControl !== undefined && (firstInvalidControl as HTMLElement).focus !== undefined) {
      console.warn("first invalid element: " + firstInvalidControl?.id);
      // small hack: ng-select cant be focused for some reason, just focus on the element above it and be done with it.
      if (firstInvalidControl?.id === 'cat-breeds' || firstInvalidControl?.id === 'dog-breeds') {
        setTimeout(() => (this.document.getElementById('pedigree') as HTMLElement).focus(), 10)
        return
      }
      setTimeout(() => (firstInvalidControl as HTMLElement).focus(), 10)
    }
  }

  displayCopaymentAsCoveragePercentage(copayment: number): number {
    if (copayment === 0) {
      return 100
    }
    return 80
  }

  ngbDateTomorrow(): NgbDateStruct {
    let tmpDate = new Date()
    tmpDate.setDate(tmpDate.getDate() + 1)
    let ngbDate = { day: tmpDate.getDate(), month: tmpDate.getMonth() + 1, year: tmpDate.getFullYear()}
    return ngbDate
  }

  ngbDateOneYearInTheFuture(): NgbDateStruct {
    let tmpDate = new Date()
    tmpDate.setFullYear(tmpDate.getFullYear() + 1)
    tmpDate.setDate(tmpDate.getDate() - 1)
    let ngbDate = { day: tmpDate.getDate(), month: tmpDate.getMonth() + 1, year: tmpDate.getFullYear()}
    return ngbDate
  }

  ngbDateEighteenYearsInThePast(): NgbDateStruct {
    let tmpDate = new Date()
    tmpDate.setFullYear(tmpDate.getFullYear() - 18)
    let ngbDate = { day: tmpDate.getDate(), month: tmpDate.getMonth() + 1, year: tmpDate.getFullYear()}
    return ngbDate
  }

  getCurrentTotal(): number | undefined {
    return this.stateService.getCurrentTotal()
  }

  findInvalidControls(): string[] {
    const invalid = [];
    const controls = this.parentInfoForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getCurrentLocaleId(): string {
    return this.stateService.getCurrentLocaleId()
  }

  getPrivacyPolicyPath(): string {
    return this.documentsService.getCurrentLanguagePrivacyPolicyPath()
  }

  getGICPath(): string {
    return this.documentsService.getCurrentLanguageGICPath()
  }

  getInformationDutyPath(): string {
    return this.documentsService.getCurrentLanguageInformationDutyPath()
  }

  getPISPath(): string {
    return this.documentsService.getCurrentLanguageProductSheetPath()
  }

  isSelectedById(id: string): boolean {
    let elem = this.document.getElementById(id)
    if (elem && (elem as HTMLInputElement).checked !== undefined ) {
      return (elem as HTMLInputElement).checked
    }
    return false
  }

  clickNewsletterConsent() {
    console.log("newsletter consent is", this.isSelectedById('newsletter-consent-switch'))
    const gtmTag = {
      event: 'newsletter_consent_setting_selected',
      value: this.isSelectedById('newsletter-consent-switch'),
    };
    this.gtmService.pushTag(gtmTag)
  }

  displayPremiumMonthly(premium: number | undefined): string {
    if (premium === undefined) {
      return '0'
    }
    let monthlyPremium: string =  (Math.round((premium/12) * 20) / 20) + ''
    return monthlyPremium
  }

  displayPremiumQuarterly(premium: number | undefined): string {
    if (premium === undefined) {
      return '0'
    }
    let quarterlyPremium: string =  (Math.round((premium/4) * 20) / 20) + ''
    return quarterlyPremium
  }

  displayPremiumYearly(premium: number | undefined): string {
    if (premium === undefined) {
      return '0'
    }
    let yearlyPremium: string =  (Math.round(premium * 20) / 20) + ''
    return yearlyPremium
  }

  setCityIfFound(event: Event) {
    let value = (event.target as HTMLInputElement).value
    if (value.length >= 4) {
      if (this.zipPlaceMapLocal.has(value)) {
        this.parentInfoForm.get('city')?.setValue(this.zipPlaceMapLocal.get(value))
      }
    }
  }

  formatDateISOToEurope(isoDate: string): string {
    return formatDate(isoDate, 'dd.MM.yyyy', 'fr')
  }

  onSubmit() {
    console.warn('submitting parent info form')
    console.warn(this.parentInfoForm.value);

    if (this.parentInfoForm.invalid) {
      console.warn("form invalid")
      this.submittedIncomplete = true
      this.scrollToFirstInvalidControl()
      // this.segment.track('Parent Info Submission Failed', {invalid_fields: this.findInvalidControls(), path: '/closing'})
      // this.gaService.gtag('event', 'parent_info_submission_failed', {invalid_fields: this.findInvalidControls(), path: '/closing'})
      return
    }
    // TODO: Tracking
    this.submittedIncomplete = false

    if (!this.stateService.validateBrokerEmailPresent()) {
      console.warn('invalid broker input')
      // this.stateService.setLocaState(state)
      return
    }

    this.loadingScreenService.showLoadingScreenPostSale(this.species)



    let state = this.parentInfoForm.value as State
    // Uppercase first letter of firstName in case the user forgot, as this looks better in terms of personalization
    if (state.firstName !== undefined) {
      state.firstName = state.firstName?.charAt(0).toUpperCase() + state.firstName?.slice(1)
    }

    // Uppercase first letter of lastName in case the user forgot, as this looks better in terms of personalization
    if (state.lastName !== undefined) {
      state.lastName = state.lastName?.charAt(0).toUpperCase() + state.lastName?.slice(1)
    }

    // Uppercase first letter of street in case the user forgot, as this looks better in terms of personalization
    if (state.street !== undefined) {
      state.street = (state.street?.charAt(0).toUpperCase() + state.street?.slice(1)).trim()
    }

    // Uppercase first letter of city in case the user forgot, as this looks better in terms of personalization
    if (state.city !== undefined) {
      state.city = (state.city?.charAt(0).toUpperCase() + state.city?.slice(1)).trim()
    }

    if (state.zip !== undefined) {
      state.zip = state.zip?.trim()
    }

    if (state.phoneNumber){
      const phoneNumber = this.phoneNumberUtil.parse(
        state.phoneNumber, "CH"
      );
      state.phoneNumber = this.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164)
    }

    if (this.newsletterConsent !== undefined) {
      state.newsletterConsentGiven = this.newsletterConsent
    }

    console.warn('State in step 3 submit', state)
    const gtmTag = {
      event: 'parent_info_submitted',
      pageName: 'parent-info',
      broker_name: state.brokerName,
      broker_code: state.brokerCode,
      flavor: this.stateService.getSnapshot().flavor,
    };
    this.gtmService.pushTag(gtmTag)
    this.stateService.setState(state).then(() => this.router.navigate(['/closing'], {queryParamsHandling: 'merge'}))


  }




}
