import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, map, BehaviorSubject } from 'rxjs';

import { State, PremiumCalculationInput, GetBrokerRequest, GetBrokerResponse, WhitelabellingUIController } from './state';
import { HttpService } from './http-service.service';
import { LoadingScreenService } from './loading-screen.service';
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private loadingScreenService: LoadingScreenService,
    private translate: TranslocoService,
  ) {
    console.warn('state service constructor')
    var paramMap: { [key: string]: string } = {}

    // We need to wait a bit here for hubspot links to work FFS...
    this.isInitialized = new Promise(res => setTimeout(res, 1000))
    .then(() => {
      // We need to grab the query parameters over the window object, since the routing is not initialized at this point in the applications
      try {
        let queryString = window.location.href.split('?')[1]

        let queryStringParts = queryString.split('&')
        for (let kv of queryStringParts) {
          let splitted = kv.split('=')
          // only decode here, as we spilt by characters which might be encoded in the URL as well, so we do it only when we are sure that we decode the values and the values only
          let k = decodeURIComponent(splitted[0])
          let v = decodeURIComponent(splitted[1])
          paramMap[k] = v
        }
      } catch (error) {
        // Do nothing, the error occurs when ther is no query string
      }

      let brokerHidden = paramMap['bch'] === 'true'
      // We need to hide the broker at the beginning if it needs to be hidden, otherwise it flashes quickly, which is not ideal
      console.warn('brokerHidden: ', brokerHidden)
      this.state.next({
        ...this.getSnapshot(),
        ...{brokerHidden: brokerHidden,}
      })
      let sessionId = paramMap['sessionId']
      let contractToken = paramMap['contractToken']
      let contractId = paramMap['contractID']
      console.warn('contractId', contractId)
      console.warn('contractToken', contractToken)

      if (contractToken && contractId) {
        console.warn('returning getStateFromContractIdAndContractToken')
        return this.getStateFromContractIdAndContractToken(contractId, contractToken)
      } else {
        console.warn('returning getStateIfSessionId')
        return this.getStateIfSessionId(sessionId)
      }
    })
    .then(() => {
      console.warn('setting referral code')
      let newState: State = {}
      let referralCode = paramMap['code']
      if (referralCode) {
        newState.referralFactoryCode = referralCode
        this.state.next({
          ...this.getSnapshot(),
          ...newState,
        })
      }

    })
    .then(() => {
      console.warn('getting broker if brokercode')
      let brokerCode = paramMap['bc']
      if ((window as any).contextFrontendConfiguration?.agentid) {
        brokerCode = (window as any).contextFrontendConfiguration?.agentid
      }
      let agentToken = paramMap['agentToken']
      if ((window as any).contextFrontendConfiguration?.agenttoken) {
        agentToken = (window as any).contextFrontendConfiguration?.agenttoken
      }
      return this.getBrokerIfBrokerCode(brokerCode, agentToken)
    })
    .then(() => {
      console.warn('wrapping up initialization')
    }).catch(() => {
      // if something goes wrong, we move to the first screen to give the user the possibility to enter good data from the start.
      this.router.navigate(['/pet-info'], { queryParamsHandling: 'preserve' })
      this.loadingScreenService.hideLoadingScreen()
    })

  }

  private state: BehaviorSubject<State> = new BehaviorSubject<State>({});
  private isInitialized: Promise<void>

  private whitelabellingUIController: BehaviorSubject<WhitelabellingUIController> = new BehaviorSubject<WhitelabellingUIController>({})
  private missingBrokerEmailNotifier: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  private currentTotal: number | undefined
  private sTotal: number | undefined
  private mTotal: number | undefined
  private lTotal: number | undefined

  private sBase: number | undefined
  private mBase: number | undefined
  private lBase: number | undefined

  private preexistingConditionsPremium: number | undefined
  private globalCoveragePremium: number | undefined;

  private brokerObject: BehaviorSubject<GetBrokerResponse>  = new BehaviorSubject<GetBrokerResponse>({});

  getCurrentTotal(): number | undefined {
    return this.currentTotal
  }

  getPreexistingConditionsTotal(): number | undefined {
    // console.warn('PEC Total:', this.preexistingConditionsPremium)
    return this.preexistingConditionsPremium ? parseFloat(this.preexistingConditionsPremium.toFixed(2)) : undefined
  }

  getGlobalCoverageTotal(): number | undefined {
    return this.globalCoveragePremium ? parseFloat(this.globalCoveragePremium.toFixed(2)) : undefined
  }

  getSTotal(): number | undefined {
    return this.sTotal
  }

  getMTotal(): number | undefined {
    return this.mTotal
  }

  getLTotal(): number | undefined {
    return this.lTotal
  }

  getSBase(): number | undefined {
    return this.sBase
  }

  getMBase(): number | undefined {
    return this.mBase
  }

  getLBase(): number | undefined {
    return this.lBase
  }

  getSnapshot(): State {
    return this.state.getValue();
  }

  getMissingBrokerEmailNotifier(): BehaviorSubject<boolean> {
    return this.missingBrokerEmailNotifier
  }

  isBrokerFlowObs: Observable<boolean> = this.getState().pipe(
    map(
      value => {
        let bc = value.brokerCode
        let bch = value.brokerHidden
        return bc !== undefined && bc !== null && bch !== true
      })
  )

  dataSharingRequestedWithCalingoFlavor: Observable<boolean> = this.getState().pipe(
    map(
      value => {
        let dsr = value.brokerDataSharingRequested
        let flavor = value.flavor
        return dsr === true && flavor === 'calingo'
      }
    )
  )

  getBrokerObjectSnapshot(): GetBrokerResponse | undefined {
    return this.brokerObject.getValue()
  }

  getBrokerObject(): BehaviorSubject<GetBrokerResponse> {
    return this.brokerObject
  }

  brokerDisplayNameObs = this.getState().pipe(
    map(
      value => {
        if (value.brokerEmail) {
          return value.brokerName + ' ' + '(' + value.brokerEmail + ', ' + value.brokerCode + ')'
        } else {
          return value.brokerName + ' ' + '(' + value.brokerCode + ')'
        }
      }
    )
  )

  getIsInitialized(): Promise<any> {
    return this.isInitialized
  }

  stateCorrespondsToBrokerMode(state: State): boolean {
    return state.brokerCode !== undefined && state.brokerCode !== null && state.brokerHidden !== true
  }

  validateBrokerEmailPresent(): boolean {
    let currentState = this.getSnapshot()
    if (this.stateCorrespondsToBrokerMode(currentState) && !currentState.brokerEmail) {
      this.missingBrokerEmailNotifier.next(true)
      return false
    }
    return true
  }

  getWhitelabellingUIController(): BehaviorSubject<WhitelabellingUIController> {
    return this.whitelabellingUIController
  }

  getWhitelabellingUIControllerSnapshot(): WhitelabellingUIController {
    return this.whitelabellingUIController.value
  }

  setWhitelabellingUIController(wuc: WhitelabellingUIController): void {
    this.whitelabellingUIController.next(wuc)
  }


  fetchPremiumForCoveragesIfPossibleWithCoverageOverrideParams(coverageOverrideParameters: CoverageOverrideParameters): Promise<any> {
    return Promise.allSettled([
      this.fetchPremiumS(coverageOverrideParameters),
      this.fetchPremiumM(coverageOverrideParameters),
      this.fetchPremiumL(coverageOverrideParameters)
    ])
  }

  async fetchPremiumCurrentTotal(currentCoverageLevel: string, coverageOverrideParameters: CoverageOverrideParameters): Promise<any> {
    let premiumCalculationInput = this.createDefaultPremiumCalculationInputIfPetDataValid(currentCoverageLevel, coverageOverrideParameters);
    if (premiumCalculationInput !== undefined) {
      return this.httpService.calculatePremium(premiumCalculationInput).then(value => {
        this.currentTotal = value.totalPremium;
        this.preexistingConditionsPremium = value.preexistingConditionsPremium;
        this.globalCoveragePremium = value.globalCoveragePremium;
      });
    }
    return Promise.resolve()
  }

  async fetchPremiumS(coverageOverrideParameters: CoverageOverrideParameters): Promise<any> {
    let premiumCalculationInput = this.createDefaultPremiumCalculationInputIfPetDataValid('s', coverageOverrideParameters);
    if (premiumCalculationInput !== undefined) {
      return this.httpService.calculatePremium(premiumCalculationInput).then(value => {
        this.sTotal = value.totalPremium
        this.sBase = value.basePremium
      });
    }
    return Promise.resolve()
  }

  async fetchPremiumM(coverageOverrideParameters: CoverageOverrideParameters): Promise<any> {
    let premiumCalculationInput = this.createDefaultPremiumCalculationInputIfPetDataValid('m', coverageOverrideParameters);
    if (premiumCalculationInput !== undefined) {
      return this.httpService.calculatePremium(premiumCalculationInput).then(value => {
        this.mTotal = value.totalPremium
        this.mBase = value.basePremium
      });
    }
    return Promise.resolve()
  }

  async fetchPremiumL(coverageOverrideParameters: CoverageOverrideParameters): Promise<any> {
    let premiumCalculationInput = this.createDefaultPremiumCalculationInputIfPetDataValid('l', coverageOverrideParameters);
    if (premiumCalculationInput !== undefined) {
      return this.httpService.calculatePremium(premiumCalculationInput).then(value => {
        this.lTotal = value.totalPremium
        this.lBase = value.basePremium
      });
    }
    return Promise.resolve()
  }

  getQuerystring(): string {
    return this.router.url
  }

  setQueryParamSessionIdIfNotPresent(sessionId: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {'sessionId': sessionId},
      queryParamsHandling: 'merge'
    } );
  }

  updateBrokerData(getBrokerRequest: GetBrokerRequest): void {
    this.httpService.getBroker(getBrokerRequest).then(value => {
      this.setBrokerData(value);
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {'bc': value.uid},
        queryParamsHandling: 'merge'
      } );
    }
    )
  }

  private setBrokerData(value: any) {
    this.brokerObject.next(value);
    console.warn('broker object', value);

    let currentBrokerEmail = this.getSnapshot().brokerEmail
    if (currentBrokerEmail) { // if there is already a broker email set, we just use this one and do not think about the which one of the new ones might or might not be correct. NOTE: This can be an issue, if the broker code gets changed while there is already a state.
      let newState = {
        brokerCode: value.uid,
        brokerEmail: currentBrokerEmail,
        brokerName: value.name,
        brokerDataSharingRequested: value.dataSharingRequested
      };
      this.state.next({
        ...this.getSnapshot(),
        ...newState,
      });
      return
    }

    let bo = this.brokerObject.getValue()
    let brokerEmail = undefined // we start with undefined, because we only wat to set an email adress if it is smart to set it, i.e. if there is no risk of setting the wrong address
    if (bo.email) {
      brokerEmail = bo.email
    } else if (bo.simpegoEmailAdresses === undefined || bo.simpegoEmailAdresses?.length === 0) {
      // Simpego did not return any email adresses
      // TODO: Decide how to handle this, since Simpego might errorously send empty list due to some synching issue, it is perhaps better to force the user to them set an address.

    } else if (bo.simpegoEmailAdresses?.length === 1) {
      // Simpego returend a single email adress
      if (bo.email === bo.simpegoEmailAdresses[0] || !bo.email) {
        // Simpego's and calingo's email address are the same
        brokerEmail = bo.simpegoEmailAdresses[0]
      } else {
        // Simpego's and calingo's email address are not the same. This is essentially the case where simpego returns more than one email address,
      }
    } else {
      // Simpego returend multiple email adresses
      if (bo.email && bo.simpegoEmailAdresses.includes(bo.email)) {
        // calingo's email adress is inculded in simpego's email adresses
      } else {
        // calingo's email adress is not inculded in simpego's email adresses

      }
    }
    let newState = {
      brokerCode: value.uid,
      brokerEmail: brokerEmail,
      brokerName: value.name,
      brokerDataSharingRequested: value.dataSharingRequested
    };
    this.state.next({
      ...this.getSnapshot(),
      ...newState,
    });
  }

  setBrokerEmail(email: string): void {
    let newState = {
      brokerEmail: email,
    }
    this.state.next({
      ...this.getSnapshot(),
      ...newState,
    })
  }

  private checkStep1Complete(value: State): boolean {
    if (typeof value === undefined || value === null) {
      return false
    }
    if (value.petName === undefined || value.petName === null || value.petName === '') {
      return false
    }
    if (value.petGender === undefined || value.petGender === null || value.petGender === '') {
      return false
    }
    if (value.species === undefined || value.species === null || value.species === '') {
      return false
    }
    if (value.breed === undefined || value.breed === null || value.breed === '') {
      return false
    }
    if (value.species === 'cat' && (value.breed === 'pedigree' || value.breed === 'cross')) {
      if (value.catBreed === undefined || value.catBreed === null || value.catBreed === '') {
        return false
      }
    }
    if (value.species === 'dog' && (value.breed === 'pedigree' || value.breed === 'cross')) {
      if (value.dogBreed === undefined || value.dogBreed === null || value.dogBreed === '') {
        return false
      }
    }
    if (value.species === 'dog' && value.breed === 'mixed') {
      if (value.tallDog === undefined || value.tallDog === null) {
        return false
      }
    }
    if (value.species === 'cat') {
      if (value.housecat === undefined || value.housecat === null) {
        return false
      }
    }
    if (value.petBirthdate === undefined || value.petBirthdate === null || value.petBirthdate === '') {
      return false
    }
    if (value.neutered === undefined || value.neutered === null) {
      return false
    }
    if (value.firstName === undefined || value.firstName === null || value.firstName === '') {
      return false
    }
    if (value.email === undefined || value.email === null || value.email === '') {
      return false
    }

    return true
  }

  private checkStep2Complete(value: State): boolean {
    if (value.coverageLevel === undefined || value.coverageLevel === null || value.coverageLevel === '') {
      return false
    }
    if (value.preexistingConditions === undefined || value.preexistingConditions === null) {
      return false
    }
    if (value.globalCoverage === undefined || value.globalCoverage === null) {
      return false
    }
    /*
    // Commented out, this coverage is not in use anyway
    if (value.liability === undefined || value.liability === null) {
      return false
    }
    */
    if (value.excess === undefined || value.excess === null) {
      return false
    }
    if (value.copayment === undefined || value.copayment === null) {
      return false
    }

    return true
  }

  private checkStep3Complete(value: State): boolean {
    if (value.lastName === undefined || value.lastName === null || value.lastName === '') {
      return false
    }
    if (value.parentGender === undefined || value.parentGender === null || value.parentGender === '') {
      return false
    }
    if (value.parentBirthdate === undefined || value.parentBirthdate === null || value.parentBirthdate === '') {
      return false
    }
    if (value.street === undefined || value.street === null || value.street === '') {
      return false
    }
    if (value.zip === undefined || value.zip === null || value.zip === '') {
      return false
    }
    if (value.city === undefined || value.city === null || value.city === '') {
      return false
    }
    if (value.phoneNumber === undefined || value.phoneNumber === null || value.phoneNumber === '') {
      return false
    }

    return true
  }

  step1Complete(): boolean {
    return this.checkStep1Complete(this.getSnapshot())
  }

  step2Complete(): boolean {
    return this.checkStep2Complete(this.getSnapshot())
  }

  step3Complete(): boolean {
    return this.checkStep3Complete(this.getSnapshot())
  }

  step1CompleteObservable: Observable<boolean> = this.state.pipe(
    map(
      value => {
        return this.checkStep1Complete(value)
      }
    )
  )

  step2CompleteObservable: Observable<boolean> = this.state.pipe(
    map(
      value => {
        return this.checkStep2Complete(value)
      }
    )
  )

  step3CompleteObservable: Observable<boolean> = this.state.pipe(
    map(
      value => {
        return this.checkStep3Complete(value)
      }
    )
  )

  getState(): BehaviorSubject<State> {
    return this.state
  }

  setLocaState(newState: Partial<State>) {
    this.state.next({
      ...this.getSnapshot(),
      ...newState,
    })
    console.warn('saved state locally:', this.getSnapshot())
  }

  async setState(newState: Partial<State>): Promise<void> {
    // First, merge the state update with the current state
    // When the email address was modified, we should start a new session, otherwise, we update the wrong contact in hubspot
    if (newState.email !== undefined && newState.email !== null && newState.email !== '' && this.getSnapshot().email !== undefined && this.getSnapshot().email !== null && this.getSnapshot().email !== '' && newState.email !== this.getSnapshot().email) {
      console.warn('mail address changed')
      console.warn('old state:',this.getSnapshot())
      console.warn('new state:', newState)
      newState.sessionId = undefined
    }

    // set language ID in state to store it in hubspot as well as MFV
    newState.localeId = this.getCurrentLocaleId()

    this.state.next({
      ...this.getSnapshot(),
      ...newState,
    })
    // then save the state, receive a session ID and merge it into the existing state
    console.warn('state service is saving state...:', this.getSnapshot())
    return this.httpService.save(this.getSnapshot())
    .then(result => {
      console.warn('state service is done saving state, result is', result)
      this.state.next({
        ...this.getSnapshot(),
        ...result,
      });
      let sessionId = this.getSnapshot().sessionId
      if (sessionId !== undefined && sessionId != null && sessionId != '') {
        this.setQueryParamSessionIdIfNotPresent(sessionId)
      }
    }).catch(() => {
      // if something goes wrong, we move to the first screen to give the user the possibility to enter good data from the start.
      this.router.navigate(['/pet-info'], { queryParamsHandling: 'preserve' })
      this.loadingScreenService.hideLoadingScreen()
    })
  }

  async getStateIfSessionId(sessionId: string): Promise<void> {
    if (sessionId) {
      return this.httpService.getState(sessionId)
      .then(result => {
        return this.setState(result)
      })
      .then(() => {
        this.moveToLatestScreenPossible();
      }).catch(() => {
        // if something goes wrong, we move to the first screen to give the user the possibility to enter good data from the start.
        this.router.navigate(['/pet-info'], { queryParamsHandling: 'preserve' })
        this.loadingScreenService.hideLoadingScreen()
      })
    }
    console.warn('returning from getStateIfSessionId')
  }

  private moveToLatestScreenPossible() {
    console.warn('setting step in getStateIfSessionId');
    if (this.step1Complete()) {
      if (this.step2Complete()) {
        if (this.step3Complete()) {
          // go to screen 4
          console.warn('going to step 4');
          this.router.navigate(['/closing'], { queryParamsHandling: 'preserve' });
        } else {
          // go to screen 3
          console.warn('going to step 3');
          this.router.navigate(['/parent-info'], { queryParamsHandling: 'preserve' });
        }

      } else {
        // go to screen 2
        console.warn('going to step 2');
        this.router.navigate(['/coverage-info'], { queryParamsHandling: 'preserve' });
      }
    } else {
      // go to screen 1
      console.warn('going to step 1');
      this.router.navigate(['/pet-info'], { queryParamsHandling: 'preserve' });
    }
  }

  async getStateFromContractIdAndContractToken(contractId: string, contractToken: string): Promise<void> {
    return this.httpService.getStateFromMfvOffer(contractId, contractToken)
    .then(result => {
      return this.setState(result)
    })
    .then(() => {
      this.moveToLatestScreenPossible();
    }).catch(() => {
      // if something goes wrong, we move to the first screen to give the user the possibility to enter good data from the start.
      this.router.navigate(['/pet-info'], { queryParamsHandling: 'preserve' })
      this.loadingScreenService.hideLoadingScreen()
    })

  }

  async getBrokerIfBrokerCode(brokerCode: string, agentToken: string): Promise<void> {
    if (brokerCode) {
      return this.httpService.getBroker({uid: brokerCode}).then(value => {
        this.setBrokerData(value)
      })
    } else if (agentToken) {
      return this.httpService.getBroker({token: agentToken}).then(value => {
        this.setBrokerData(value)
      })
    }
  }

  createDefaultPremiumCalculationInputIfPetDataValid(coverageLevel: string, coverageOverrideParameters: CoverageOverrideParameters): PremiumCalculationInput | undefined {
    let state = this.getSnapshot()
    if (state.petGender === undefined || state.petGender === null || state.petGender === '') {
      return undefined
    }
    if (state.species === undefined || state.species === null || state.species === '') {
      return undefined
    }
    if (state.breed === undefined || state.breed === null || state.breed === '') {
      return undefined
    }
    if (state.species === 'Cat' && (state.breed === 'Pedigree' || state.breed === 'Cross')) {
      if (state.catBreed === undefined || state.catBreed === null || state.catBreed === '') {
        return undefined
      }
    }
    if (state.species === 'Dog' && (state.breed === 'Pedigree' || state.breed === 'Cross')) {
      if (state.dogBreed === undefined || state.dogBreed === null || state.dogBreed === '') {
        return undefined
      }
    }
    if (state.species === 'Dog' && state.breed === 'Mixed') {
      if (state.tallDog === undefined || state.tallDog === null) {
        return undefined
      }
    }
    if (state.petBirthdate === undefined || state.petBirthdate === null || state.petBirthdate === '') {
      return undefined
    }
    if (state.neutered === undefined || state.neutered === null) {
      return undefined
    }

    let premiumCalculationInput: PremiumCalculationInput = {
      brokerCode: state.brokerCode,
      petGender: state.petGender,
      species: state.species,
      breed: state.breed,
      catBreed: state.catBreed ?? '', // only conditionally necessary, put backup value
      dogBreed: state.dogBreed ?? '', // only conditionally necessary, put backup value
      tallDog: state.tallDog ?? false, // only conditionally necessary, put backup value
      petBirthdate: state.petBirthdate,
      neutered: state.neutered,
      coverageLevel: coverageLevel,
      preexistingConditions: coverageOverrideParameters.preexistingConditionsOverride ?? (state.preexistingConditions ?? false),
      globalCoverage: coverageOverrideParameters.globalCoverageOverride ?? (state.globalCoverage ?? false),
      liability: coverageOverrideParameters.liabilityOverride ?? (state.liability ?? false),
      excess: coverageOverrideParameters.excessOverride ?? (state.excess ?? 500),
      copayment: coverageOverrideParameters.copaymnetOverride ?? (state.copayment ?? 20),
      startDate: coverageOverrideParameters.startDateOverride ?? (state.startDate ?? undefined),
      paymentFrequency: coverageOverrideParameters.paymentFrequencyOverride ?? (state.paymentFrequency ?? undefined)
    }
    return premiumCalculationInput

  }

  getCurrentLocaleId(): string {
    return this.translate.getActiveLang()
  }
}

export interface CoverageOverrideParameters  {
  preexistingConditionsOverride?: boolean,
  globalCoverageOverride?: boolean,
  liabilityOverride?: boolean,
  excessOverride?: number,
  copaymnetOverride?: number
  startDateOverride?: string
  paymentFrequencyOverride?: string
}
