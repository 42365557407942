import {Injectable} from '@angular/core';
import {StateService} from './state.service';
import {filter} from "rxjs";
import {environment} from "../environments/environment";
import {CustomCompanyData} from "./state";
import { languageCodeFromLocaleId } from './utils';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  customCompanyData?: CustomCompanyData;
  private documentsBasePath: string = 'https://calingopublicdownloads.s3.eu-central-1.amazonaws.com/pet/latest'

  constructor(
    private stateService: StateService,
  ) {
    stateService.getState().pipe(filter(state => !!state.customCssCompanyCode)).subscribe(state => {
      if (state.customCssCompanyCode) {
        this.customCompanyData = environment.companies.find(company => company.code === state.customCssCompanyCode)?.data;
      }
    });
  }

  getDocumentsBasePath(): string {
    return this.documentsBasePath
  }



  getCurrentLanguagePath(): string {
    let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
    return this.getDocumentsBasePath() + '/' + languageCodeFromLocaleId(lid);
  }

  getCurrentLanguageGICPath(): string {
    if (this.customCompanyData?.documentGICPageUrls) {
      let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
      let lc = languageCodeFromLocaleId(lid)
      if (lc === 'en') {
        return this.customCompanyData.documentGICPageUrls.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.documentGICPageUrls.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.documentGICPageUrls.it
      }
      return this.customCompanyData.documentGICPageUrls.de // fallback
    } else {
      return this.getCurrentLanguagePath() + '/' + 'gic.pdf'
    }
  }

  getCurrentLanguagePrivacyPolicyPath(): string {
    let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
    let lc = languageCodeFromLocaleId(lid)
    if (this.customCompanyData?.documentPPPageUrls) {
      if (lc === 'en') {
        return this.customCompanyData.documentPPPageUrls.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.documentPPPageUrls.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.documentPPPageUrls.it
      }
      return this.customCompanyData.documentPPPageUrls.de // fallback

    } else {
      if (lc === 'en') {
        return "https://en.pet.calingo.ch/datenschutzerklaerung"
      }
      if (lc === 'fr') {
        return "https://fr.pet.calingo.ch/datenschutzerklaerung"
      }
      if (lc === 'it') {
        return "https://it.pet.calingo.ch/datenschutzerklaerung"
      }
      return "https://pet.calingo.ch/datenschutzerklaerung" // fallback
    }
  }

  getCurrentLanguageInformationDutyPath(): string {
    if (this.customCompanyData?.documentIDPageUrls) {
      let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
      let lc = languageCodeFromLocaleId(lid)
      if (lc === 'en') {
        return this.customCompanyData.documentIDPageUrls.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.documentIDPageUrls.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.documentIDPageUrls.it
      }
      return this.customCompanyData.documentIDPageUrls.de // fallback

    } else {
      return this.getCurrentLanguagePath() + '/' + 'information_duty_calingo.pdf'
    }
  }

  getCurrentLanguageProductSheetPath(): string {
    if (this.customCompanyData?.documentPISPageUrls) {
      let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
      let lc = languageCodeFromLocaleId(lid)
      if (lc === 'en') {
        return this.customCompanyData.documentPISPageUrls.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.documentPISPageUrls.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.documentPISPageUrls.it
      }
      return this.customCompanyData.documentPISPageUrls.de // fallback

    } else {
      return this.getCurrentLanguagePath() + '/' + 'product_information.pdf'
    }
  }
}
