import { Component, OnInit } from '@angular/core';
import { StateService } from '../state.service';

@Component({
  selector: 'app-success-b2c',
  templateUrl: './success-b2c.component.html',
  styleUrls: ['./success-b2c.component.scss']
})
export class SuccessB2cComponent implements OnInit {

  constructor(
    private stateService: StateService,
  ) { }

  petName: string|undefined
  parentName: string|undefined

  ngOnInit(): void {
    this.stateService.getState().subscribe(
      value => {
        this.petName = value.petName
        this.parentName = value.firstName
      }
    )
  }

  showNames(): boolean {
    return this.petName !== undefined && this.petName !== null && this.petName !== '' && this.parentName !== undefined && this.parentName !== null && this.parentName !== ''
  }

}
