import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomCompanyData } from './state';
import { StateService } from './state.service';
import { languageCodeFromLocaleId } from './utils';

@Injectable({
  providedIn: 'root'
})
export class SuccessPageService {
  customCompanyData?: CustomCompanyData;

  constructor(
    private stateService: StateService,
  ) {
    stateService.getState().pipe(filter(state => !!state.customCssCompanyCode)).subscribe(state => {
      if (state.customCssCompanyCode) {
        this.customCompanyData = environment.companies.find(company => company.code === state.customCssCompanyCode)?.data;
      }
    });
  }

  getSuccessPagePurchaseB2C(): string {
    if (this.customCompanyData?.successPagesPurchaseB2C) {
      let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
      let lc = languageCodeFromLocaleId(lid)
      if (lc === 'en') {
        return this.customCompanyData.successPagesPurchaseB2C.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.successPagesPurchaseB2C.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.successPagesPurchaseB2C.it
      }
      return this.customCompanyData.successPagesPurchaseB2C.de // fallback
    } else {
      
    }
    return ''
  }

  getSuccessPagePurchaseB2B2C(): string {
    if (this.customCompanyData?.successPagesPurchaseB2B2C) {
      let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
      let lc = languageCodeFromLocaleId(lid)
      if (lc === 'en') {
        return this.customCompanyData.successPagesPurchaseB2B2C.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.successPagesPurchaseB2B2C.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.successPagesPurchaseB2B2C.it
      }
      return this.customCompanyData.successPagesPurchaseB2B2C.de // fallback
    } else {
      
    }
    return ''  }

  getSuccessPageOfferB2C(): string {
    if (this.customCompanyData?.successPagesOfferB2C) {
      let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
      let lc = languageCodeFromLocaleId(lid)
      if (lc === 'en') {
        return this.customCompanyData.successPagesOfferB2C.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.successPagesOfferB2C.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.successPagesOfferB2C.it
      }
      return this.customCompanyData.successPagesOfferB2C.de // fallback
    } else {
      
    }
    return ''
  }

  getSuccessPageOfferB2B2C(): string {
    if (this.customCompanyData?.successPagesOfferB2B2C) {
      let lid = this.stateService.getCurrentLocaleId().toLocaleLowerCase()
      let lc = languageCodeFromLocaleId(lid)
      if (lc === 'en') {
        return this.customCompanyData.successPagesOfferB2B2C.en
      }
      if (lc === 'fr') {
        return this.customCompanyData.successPagesOfferB2B2C.fr
      }
      if (lc === 'it') {
        return this.customCompanyData.successPagesOfferB2B2C.it
      }
      return this.customCompanyData.successPagesOfferB2B2C.de // fallback
    } else {
      
    }
    return ''
  }

}


