import { Component, OnInit } from '@angular/core';
import { StateService } from '../state.service';

@Component({
  selector: 'app-closing-broker',
  templateUrl: './closing-broker.component.html',
  styleUrls: ['./closing-broker.component.scss']
})
export class ClosingBrokerComponent implements OnInit {

  constructor(
    private stateService: StateService,
  ) { }

  petName: string|undefined
  parentName: string|undefined
  species: string|undefined

  ngOnInit(): void {
    this.stateService.getState().subscribe(
      value => {
        this.petName = value.petName
        this.parentName = value.firstName
        this.species = value.species
      }
    )
  }

  showNames(): boolean {
    return this.petName !== undefined && this.petName !== null && this.petName !== '' && this.parentName !== undefined && this.parentName !== null && this.parentName !== ''
  }

  showDog(): boolean {
    return this.species === 'dog' || this.species === null || this.species === undefined || this.species === ''
  }

}
