import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PetInfoComponent} from './pet-info/pet-info.component';
import {CoverageInfoComponent} from './coverage-info/coverage-info.component';
import {ParentInfoComponent} from './parent-info/parent-info.component';
import {ClosingBrokerComponent} from './closing-broker/closing-broker.component';
import {SuccessB2cComponent} from './success-b2c/success-b2c.component';
import {BeginAndPayComponent} from './begin-and-pay/begin-and-pay.component';
import {AppComponent} from "./app.component";
import {LangWrapperComponent} from "./lang-wrapper/lang-wrapper.component";


const routes: Routes = [
  {
    path: '', component: LangWrapperComponent, children: [
      {path: 'pet-info', component: PetInfoComponent},
      {path: 'coverage-info', component: CoverageInfoComponent},
      {path: 'parent-info', component: ParentInfoComponent},
      {path: 'closing', component: BeginAndPayComponent}
    ]
  },
  {path: '**', redirectTo: 'pet-info', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
