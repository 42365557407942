import { Component, OnInit } from '@angular/core';
import { StateService } from '../state.service';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-language-choice',
  templateUrl: './language-choice.component.html',
  styleUrls: [ './language-choice.component.scss']
})
export class LanguageChoiceComponent implements OnInit {

  currentLanguage: string

  expanded: boolean = false

  constructor(
    private stateService: StateService,
    private translate: TranslocoService,
  ) {
    this.currentLanguage = translate.getActiveLang()
  }

  ngOnInit(): void {
    this.translate.langChanges$.subscribe(lang => {
      this.currentLanguage = lang;
    });
  }

  expand(): void {
    this.expanded = true
  }

  collapse(): void {
    this.expanded = false
  }

  setLanguage(lang: string): void {
    this.translate.setActiveLang(lang);
    localStorage.setItem('lang', lang);
  }
}
