import { Injectable } from '@angular/core';
import { NgbModule, NgbDateNativeAdapter, NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { isoToNgbDate } from './utils';
import { formatNumber } from '@angular/common';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class NgbDateISOAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      return isoToNgbDate(value)
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year+ this.DELIMITER+('00'+date.month).slice(-2) + this.DELIMITER + ('00'+date.day).slice(-2)   : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class EuroDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = '.';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		return date ? formatNumber(date.day, 'fr', '2.0') + this.DELIMITER + formatNumber(date.month, 'fr', '2.0') + this.DELIMITER + date.year : '';
	}
}