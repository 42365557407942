<div class="flex-wrapper">
    <div class="sub-page-root-begin-and-pay">
        <h3 class="h3-closing-screen">{{'closing-pet-information-header' | transloco : {petName  : petName } }} <a class="edit-link" routerLink="/pet-info" queryParamsHandling="preserve">✎</a></h3>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-information-species-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p *ngIf="this.species === 'cat'" [innerHtml]="'closing-pet-information-species-value-cat' | transloco"></p>
                <p *ngIf="this.species === 'dog'" [innerHtml]="'closing-pet-information-species-value-dog' | transloco"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-information-gender-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p *ngIf="this.petGender === 'm'" [innerHtml]="'closing-pet-information-gender-value-m' | transloco"></p>
                <p *ngIf="this.petGender === 'f'" [innerHtml]="'closing-pet-information-gender-value-f' | transloco"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-information-breed-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p *ngIf="this.breed === 'pedigree'" [innerHtml]="'closing-pet-information-breed-value-pedigree' | transloco"></p>
                <p *ngIf="this.breed === 'cross'" [innerHtml]="'closing-pet-information-breed-value-cross' | transloco"></p>
                <p *ngIf="this.breed === 'mixed'" [innerHtml]="'closing-pet-information-breed-value-mixed' | transloco"></p>
            </div>
        </div>


        <div class="closing-key-value" *ngIf="this.species === 'cat' && this.catBreed !== undefined">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-information-cat-breed-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-pet-information-cat-breed-value' | transloco : {catBreed  : catBreed }"></p>
            </div>
        </div>

        <div class="closing-key-value" *ngIf="this.species === 'dog' && this.dogBreed !== undefined">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-information-dog-breed-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-pet-information-dog-breed-value' | transloco : {dogBreed  : dogBreed }"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-information-pet-birthdate-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p>{{formatDateISOToEurope(petBirthdate)}}</p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-information-neutered-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p *ngIf="this.neutered" [innerHtml]="'closing-pet-information-neutered-value-yes' | transloco"></p>
                <p *ngIf="!this.neutered" [innerHtml]="'closing-pet-information-neutered-value-no' | transloco"></p>
            </div>
        </div>

        <h3 class="h3-closing-screen">{{ 'closing-pet-protection-header' | transloco : {petName  : petName } }} <a class="edit-link" routerLink="/coverage-info" queryParamsHandling="preserve">✎</a></h3>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-protection-sum-insured-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p *ngIf="this.coverageLevel === 's'" [innerHtml]="'closing-pet-protection-sum-insured-value-s' | transloco"></p>
                <p *ngIf="this.coverageLevel === 'm'" [innerHtml]="'pet-protection-sum-insured-value-m' | transloco"></p>
                <p *ngIf="this.coverageLevel === 'l'" [innerHtml]="'pet-protection-sum-insured-value-l' | transloco"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-protection-coverage-level-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p *ngIf="this.coverageLevel === 's'" [innerHtml]="'closing-pet-protection-coverage-level-value-s' | transloco"></p>
                <p *ngIf="this.coverageLevel === 'm'" [innerHtml]="'closing-pet-protection-coverage-level-value-m' | transloco"></p>
                <p *ngIf="this.coverageLevel === 'l'" [innerHtml]="'closing-pet-protection-coverage-level-value-l' | transloco"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-protection-excess-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-pet-protection-excess-value' | transloco : {excess  : excess }"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-protection-copayment-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-pet-protection-copayment-value' | transloco : {copayment : displayCopaymentAsCoveragePercentage(copayment)}"></p>
            </div>
        </div>

        <div class="closing-key-value" *ngIf="this.globalCoverage">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-protection-addtl-coverage-global-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-pet-protection-addtl-coverage-global-value' | transloco"></p>
            </div>
        </div>

        <div class="closing-key-value" *ngIf="this.preexistingConditions">
            <div class="closing-key">
                <p [innerHtml]="'closing-pet-protection-addtl-coverage-peexisting-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-pet-protection-addtl-coverage-peexisting-value' | transloco"></p>
            </div>
        </div>

        <h3 class="h3-closing-screen">{{ 'closing-parent-information-header' | transloco }} <a class="edit-link" routerLink="/parent-info" queryParamsHandling="preserve">✎</a></h3>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-firstname-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-parent-information-firstname-value' | transloco : {firstName : firstName}"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-lastname-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-parent-information-lastname-value' | transloco : {lastName : lastName}"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-gender-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p *ngIf="this.parentGender === 'm'" [innerHtml]="'closing-parent-information-gender-value-m' | transloco"></p>
                <p *ngIf="this.parentGender === 'f'" [innerHtml]="'closing-parent-information-gender-value-f' | transloco"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-birthdate-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p>{{ formatDateISOToEurope(parentBirthdate)}}</p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-street-and-number-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-parent-information-street-and-number-value' | transloco : {streetnumbernumber : street + (number?' ' + number:'') }"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-zip-and-city-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-parent-information-zip-and-city-value' | transloco : {zipcity : zip + ' ' + city }"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-phone-number-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-parent-information-phone-number-value' | transloco : {phoneNumber  : phoneNumber }"></p>
            </div>
        </div>

        <div class="closing-key-value">
            <div class="closing-key">
                <p [innerHtml]="'closing-parent-information-email-key' | transloco"></p>
            </div>
            <div class="closing-value">
                <p [innerHtml]="'closing-parent-information-email-value' | transloco : {email  : email }"></p>
            </div>
        </div>


        <h3 [innerHtml]="'closing-begin-and-pay-header' | transloco" class="h3-closing-screen"></h3>

        <form id="begin-and-pay-form" [formGroup]="beginAndPayForm">

            <h4>{{ 'closing-payment-frequency-header' | transloco }} <span (click)="showAlert(paymentFrequencyInfoTitle, paymentFrequencyInfoMessage)" class="infobutton">&#9432;</span></h4>
            <div class="choice-wrapper-short-4">

                <div>
                    <label for="monthly">
                        <input id="monthly" class="form-control" value="m" type="radio"
                            (click)="track('payment-frequency', 'selector', 'monthly')" formControlName="paymentFrequency">
                        <div
                            [class.sibling-selected]="isSelectedById('quarterly') || isSelectedById('biyearly') || isSelectedById('yearly')" class="choice-div-short-pet">
                            <p [innerHtml]="'closing-payment-frequency-monthly-value' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                </div>


                <div>
                    <label for="quarterly">
                        <input id="quarterly" class="form-control" value="q" type="radio"
                            (click)="track('payment-frequency', 'selector', 'quarterly')" formControlName="paymentFrequency">
                        <div
                            [class.sibling-selected]="isSelectedById('monthly') || isSelectedById('biyearly') || isSelectedById('yearly')" class="choice-div-short-pet">
                            <p [innerHtml]="'closing-payment-frequency-quarterly-value' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                    <p class="oneline-explanation-button">{{ 'closing-payment-frequency-quarterly-discount' | transloco }}</p>
                </div>


                <div>
                    <label for="biyearly">
                        <input id="biyearly" class="form-control" value="b" type="radio"
                            (click)="track('payment-frequency', 'selector', 'quarterly')" formControlName="paymentFrequency">
                        <div
                            [class.sibling-selected]="isSelectedById('monthly') || isSelectedById('quarterly') || isSelectedById('yearly')" class="choice-div-short-pet">
                            <p [innerHtml]="'closing-payment-frequency-biyearly-value' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                    <p class="oneline-explanation-button">{{ 'closing-payment-frequency-biyearly-discount' | transloco }}</p>
                </div>


                <div>
                    <label for="yearly">
                        <input id="yearly" class="form-control" value="y" type="radio"
                            (click)="track('payment-frequency', 'selector', 'yearly')" formControlName="paymentFrequency">
                        <div
                            [class.sibling-selected]="isSelectedById('monthly') || isSelectedById('quarterly') || isSelectedById('biyearly')" class="choice-div-short-pet">
                            <p [innerHtml]="'closing-payment-frequency-yearly-value' | transloco" class="center-center-p bold"></p>
                        </div>
                    </label>
                    <p class="oneline-explanation-button">{{ 'closing-payment-frequency-yearly-discount' | transloco }}</p>
                </div>

                


            </div>
            <!-- <p [innerHtml]="'closing-payment-by-invoice' | transloco" class="oneline-explanation-left"></p> -->

            <h4 [innerHtml]="'closing-start-date-options-header' | transloco : {petName  : petName }"></h4>
            <div class="choice-wrapper-short-2">
                <label for="start-tomorrow">
                    <input id="start-tomorrow" class="form-control" value="true" type="radio"
                        (click)="track('start-date', 'selector', 'tomorrow')" formControlName="startTomorrow">
                    <div
                        [class.sibling-selected]="isSelectedById('start-another-day')" class="choice-div-short-pet">
                        <p [innerHtml]="'closing-start-date-options-tomorrow-value' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
                <label for="start-another-day">
                    <input id="start-another-day" class="form-control" value="false" type="radio"
                        (click)="track('start-date', 'selector', 'another-day')" formControlName="startTomorrow">
                    <div
                        [class.sibling-selected]="isSelectedById('start-tomorrow')" class="choice-div-short-pet">
                        <p [innerHtml]="'closing-start-date-options-another-day-value' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
            </div>

            <div *ngIf="!startTomorrow()" class="text-input-wrapper-with-header" id="custom-start-date">
                <p [innerHtml]="'closing-start-date-datepicker-header' | transloco"></p>
                <input (ngModelChange)="startDate=$event" [class.submitted-incomplete]="submittedIncomplete" [minDate]="ngbDateTomorrow()" [maxDate]="ngbDateOneYearInTheFuture()" outsideDays="hidden"
                [weekdays]="false" id="start-date-picker" class="form-control text-input" formControlName="startDate" [placeholder]="'datepicker-placeholder'|transloco"
                (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker" (keydown.enter)="blur($event.target)" (blur)="track('start-date', 'datepicker', startDate)">

                <p [innerHtml]="'pclosing-start-date-explanation' | transloco" class="oneline-explanation"></p>

            </div>

            <div class="infodiv" id="waiting-period-infodiv">
                <div class="infodiv-header">
                    <h5 [innerHtml]="'closing-grace-period-info-header' | transloco"></h5>
                </div>
                <div [innerHtml]="'closing-grace-period-info-content' | transloco" class="infodiv-content"></div>
            </div>

            <br style="height: 0;"> <!--Dummy element to prevent margin collapsing-->

            <h3 [innerHtml]="'closing-application-questions-big-header' | transloco" class="h3-closing-screen"></h3>

            <div class="infodiv">
                <div class="infodiv-header">
                    <p [innerHtml]="'closing-pet-application-questions-header' | transloco"></p>
                </div>
                <div class="infodiv-content">
                    <ul>
                        <li [innerHtml]="'closing-application-question-1' | transloco"></li>
                        <li [innerHtml]="'closing-application-question-2' | transloco"></li>
                        <li [innerHtml]="'closing-application-question-7' | transloco"></li>
                        <li [innerHtml]="'closing-application-question-3' | transloco"></li>
                        <li [innerHtml]="'closing-application-question-5' | transloco"></li>
                    </ul>
                </div>
            </div>

        </form>

    </div>

    <footer *ngIf="this.getCurrentTotal() !== undefined" class="closing-bottom">

        <div class="closing-bottom-content">
            <div class="total-amount-div-with-button">
                <h4 *ngIf="this.showPremiumLoader" [innerHtml]="'closing-total-amount-text-loader' | transloco"></h4>
                
                <h4 *ngIf="this.isMonthlyPayment() && !this.showPremiumLoader" [innerHtml]="('closing-total-amount-text-monthly' | transloco : {displayPremiumMonthlygetCurrentTotalnumberfr : displayPremiumMonthly(getCurrentTotal()) | number:'1.2-2'}) + ' ' + (this.pricePercentageReduction !== undefined ? ('percentage-pricing-reduction-text' | transloco : {pricePercentageReduction : this.pricePercentageReduction}) : '')"></h4>
                <h4 *ngIf="this.isQuarterlyPayment() && !this.showPremiumLoader" [innerHtml]="('closing-total-amount-text-quarterly' | transloco : {displayPremiumQuarterlygetCurrentTotalnumberfr : displayPremiumQuarterly(getCurrentTotal()) | number:'1.2-2'}) + ' ' + (this.pricePercentageReduction !== undefined ? ('percentage-pricing-reduction-text' | transloco : {pricePercentageReduction : this.pricePercentageReduction}) : '')"></h4>
                <h4 *ngIf="this.isBiYearlyPayment() && !this.showPremiumLoader" [innerHtml]="('closing-total-amount-text-biyearly' | transloco : {displayPremiumBiYearlygetCurrentTotalnumberfr : displayPremiumBiYearly(getCurrentTotal()) | number:'1.2-2'}) + ' ' + (this.pricePercentageReduction !== undefined ? ('percentage-pricing-reduction-text' | transloco : {pricePercentageReduction : this.pricePercentageReduction}) : '')"></h4>
                <h4 *ngIf="this.isYearlyPayment() && !this.showPremiumLoader" [innerHtml]="('closing-total-amount-text-yearly' | transloco : {displayPremiumYearlygetCurrentTotalnumberfr : displayPremiumYearly(getCurrentTotal()) | number:'1.2-2'}) + ' ' + (this.pricePercentageReduction !== undefined ? ('percentage-pricing-reduction-text' | transloco : {pricePercentageReduction : this.pricePercentageReduction}) : '')"></h4>
                <!--
                    <h4 *ngIf="!this.isQuarterlyPayment()" [innerHtml]="'closing-total-amount-text-yearly' | transloco"></h4>
                -->
                <p *ngIf="!this.showRiskCarrierFooter()" [innerHtml]="'closing-submit-button-explanation' | transloco : {getGICPath : getGICPath(), getPrivacyPolicyPath: getPrivacyPolicyPath(), getInformationDutyPath: getInformationDutyPath(), getPISPath: getPISPath() }" class="oneline-explanation"></p>

                <p *ngIf="this.showRiskCarrierFooter() && !this.isBrokerFlow" [innerHtml]="'closing-submit-button-explanation-risk-carrier-b-2-c' | transloco : {getGICPath : getGICPath(), getPrivacyPolicyPath: getPrivacyPolicyPath(), getInformationDutyPath: getInformationDutyPath(), getPISPath: getPISPath() }" class="oneline-explanation"></p>

                <p *ngIf="this.showRiskCarrierFooter() && this.isBrokerFlow" [innerHtml]="'closing-submit-button-explanation-risk-carrier-b-2-b-2-c' | transloco : {getGICPath : getGICPath(), getPrivacyPolicyPath: getPrivacyPolicyPath(), getInformationDutyPath: getInformationDutyPath(), getPISPath: getPISPath() }" class="oneline-explanation"></p>

            </div>


            <div class="two-buttons-wrapper-dynamic-direction">
                <div *ngIf="this.showB2COfferButton()">
                    <button [innerHtml]="'closing-request-offer-button-customer' | transloco" (click)="onSubmit('send-pdf-offer-customer')" form="parent-info-form"></button>
                </div>
                <div *ngIf="this.showDynamicDispatchControlOfferButton()">
                    <button [innerHtml]="'closing-request-offer-button-customer' | transloco" (click)="onSubmit('send-pdf-offer-dynamic-dispatch-control')" form="parent-info-form"></button>
                </div>
                <div class="send-offer-link-button-wrapper">
                    <button [innerHtml]="'closing-submit-button-text' | transloco" (click)="onSubmit('close')" form="parent-info-form"></button>
                    <p [innerHtml]="'pclosing-close-button-explanation' | transloco" class="oneline-explanation-inverted"></p>
                </div>
            </div>

        </div>
        <div *ngIf="this.isBrokerFlow && !this.showDynamicDispatchControlOfferButton()" class="two-send-offer-buttons-wrapper">
            <div (click)="this.toggleShowSendOfferButtons()">
                <h4 *ngIf="this.showSendOfferButtons" [innerHtml]="'closing-send-offer-title-open' | transloco" class="send-offer-dropdown-text"></h4>
                <h4 *ngIf="!this.showSendOfferButtons" [innerHtml]="'closing-send-offer-title-closed' | transloco" class="send-offer-dropdown-text"></h4>
            </div>
            <div *ngIf="this.showSendOfferButtons" class="two-buttons-wrapper-horizontal">
                <button [innerHtml]="'closing-send-offer-button-customer' | transloco" (click)="onSubmit('send-pdf-offer-customer')" form="parent-info-form"></button>
                <button [innerHtml]="'closing-send-offer-button-broker' | transloco" (click)="onSubmit('send-pdf-offer-broker')" form="parent-info-form"></button>
            </div>
        </div>

    </footer>
</div>
