import {isDevMode, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
// import { SegmentModule } from 'ngx-segment-analytics';
// import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {  BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Locale data required to show prices in a "swiss" format in decimal pipe
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PetInfoComponent } from './pet-info/pet-info.component';
import { CoverageInfoComponent } from './coverage-info/coverage-info.component';
import { HomeComponent } from './home/home.component';
import { StepperComponent } from './stepper/stepper.component';
import { LanguageChoiceComponent } from './language-choice/language-choice.component';
import { PhoneComponent } from './phone/phone.component';
import { AlertComponent } from './alert/alert.component';
import { ParentInfoComponent } from './parent-info/parent-info.component';
import { EuroDateParserFormatter, NgbDateISOAdapter } from './ngbDatepickerAdapterFormatter';
import { BrokerDisplayComponent } from './broker-display/broker-display.component';
import { HeroesComponent } from './heroes/heroes.component';
import { ClosingBrokerComponent } from './closing-broker/closing-broker.component';
import { SuccessB2cComponent } from './success-b2c/success-b2c.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { environment } from 'src/environments/environment';
import { AcceptAlertComponent } from './accept-alert/accept-alert.component';
import { BeginAndPayComponent } from './begin-and-pay/begin-and-pay.component';
import {TranslocoHttpLoader} from "./transloco-loader";
import {provideTransloco, TranslocoModule} from "@ngneat/transloco";
import { LangWrapperComponent } from './lang-wrapper/lang-wrapper.component';

@NgModule({
  declarations: [
    AppComponent,
    PetInfoComponent,
    CoverageInfoComponent,
    HomeComponent,
    StepperComponent,
    LanguageChoiceComponent,
    PhoneComponent,
    AlertComponent,
    ParentInfoComponent,
    BrokerDisplayComponent,
    HeroesComponent,
    ClosingBrokerComponent,
    SuccessB2cComponent,
    LoadingScreenComponent,
    AcceptAlertComponent,
    BeginAndPayComponent,
    LangWrapperComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    // SegmentModule.forRoot({ apiKey: 'j52jkyPPQfmh8DFYwFtcAxibEjRKEiKe', debug: true, loadOnInitialization: true }),
    // NgxGoogleAnalyticsModule.forRoot('G-LQ9F6CSBZM'),
    // NgxGoogleAnalyticsRouterModule,
    SimpleModalModule.forRoot({container: "modal-container"}),
    NgbModule,
    BrowserAnimationsModule,

    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
    TranslocoModule

  ],
  providers: [
    DatePipe,
    { provide: NgbDateAdapter, useClass: NgbDateISOAdapter },
    { provide: NgbDateParserFormatter, useClass: EuroDateParserFormatter },
    provideTransloco({
      config: {
        availableLangs: ['en', 'de', 'fr', 'it'],
        defaultLang: 'de',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
