<div class="broker-success">
    <div class="broker-vertically-split">
        <div class="broker-success-left">
            <div class="top-left">
                <img class="calingo-logo-success" src="assets/logo_calingo_petrol_clipped.svg">
            </div>
            <div class="broker-success-left-content">
                <p [innerHtml]="'broker-success-broker-success-page-text-no-names' | transloco" *ngIf="!showNames()"></p>
                <p [innerHtml]="'broker-success-broker-success-page-text-names' | transloco : {parentName  : parentName }" *ngIf="showNames()"></p>
    
                <div class="two-buttons-wrapper">
                    <button [innerHtml]="'broker-success-broker-success-new-offer-button-text' | transloco"></button>
                </div>
            </div>

        </div>
        <div class="broker-success-right">
            <img *ngIf="showDog()" class="half-screen-vertical" src="assets/dog_success_page_half_vertical.svg">
            <img *ngIf="!showDog()" class="half-screen-vertical" src="assets/cat_success_page_half_vertical.svg">
        </div>
    </div>

    <div class="horizontal-filler-div">

    </div>



</div>
