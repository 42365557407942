import {Component, OnInit} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {ActivatedRoute} from "@angular/router";
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-lang-wrapper',
  templateUrl: './lang-wrapper.component.html',
  styleUrls: ['./lang-wrapper.component.scss']
})
export class LangWrapperComponent implements OnInit {

  constructor(private translate: TranslocoService, private route: ActivatedRoute, private cookieService: CookieService) {
  }

  ngOnInit(): void {
    var contextLang = undefined
    if ((window as any).contextFrontendConfiguration?.lang) {
      console.warn('langwrapper found context conf')
      contextLang = (window as any).contextFrontendConfiguration?.lang
      console.warn('langwrapper found context conf lang', contextLang)
    } else {
      console.warn('langwrapper did not find context')
    }

    this.translate.setActiveLang(contextLang || this.cookieService.get('lang') || localStorage.getItem('lang') || this.translate.getActiveLang() || 'de')

    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('lang')) {
        const lang = params['lang'];
        if (!lang) {
          const localStorageLang = localStorage.getItem('lang');
          if (localStorageLang) {
            this.translate.setActiveLang(localStorageLang);
          }
          return;
        } else {
          this.translate.setActiveLang(lang);
          localStorage.setItem('lang', lang);
        }
      }
    });
  }

}
