<div class="b2c-success">
    <div class="b2c-success-top">
        <img src="assets/sucesspage_closing_half_horizontal.png">
    </div>

    <div class="b2c-success-bottom">
        <p [innerHtml]="'b-2-c-success-success-page-text-no-names' | transloco" *ngIf="!showNames()"></p>
        <p [innerHtml]="'b-2-c-success-success-page-text-names' | transloco" *ngIf="showNames()"></p>
        <div class="two-buttons-wrapper">
            <button [innerHtml]="'b-2-c-success-back-to-website-button' | transloco"></button>
        </div>
    </div>
</div>