<!--
    Alternative (from HH/PL frontend):
<div class="contact">
    <button class="btn btn-link" type="button">
        <a class="phone-number" href="tel:0800 00 57 90">0800 00 57 90</a>
        <img src="assets/phone.svg">
    </button>
</div>
-->

<!--

-->
<div (mouseenter)="setElemHidden('phone-number-href', false)" (mouseleave)="setElemHidden('phone-number-href', true)"
     (click)="toggleElemHidden('phone-number-href')" class="contact">
  <a hidden id="phone-number-href" class="phone-number" [href]="'tel:'+phoneNumber">{{phoneNumber}}</a>
  <div id="phone-icon"></div>
</div>
