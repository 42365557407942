import { AbstractControl, ValidationErrors } from "@angular/forms";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export function isoToNgbDate(isoDate: string | null): NgbDateStruct | null {
    if (isoDate) {
        let dateParts = isoDate.split('-');
        return {
        day : parseInt(dateParts[2], 10),
        month : parseInt(dateParts[1], 10),
        year : parseInt(dateParts[0], 10)
        }
    }
    return null
}

export function ngbDateToIso(ngbDate: NgbDateStruct | null): string | null {
    if (ngbDate) {
        let dayStr = '0' + ngbDate.day
        let monthStr = '0' + ngbDate.month
        let yearStr = '' + ngbDate.year

        return yearStr + '-' + monthStr.slice(-2) + '-' + dayStr.slice(-2)
    }
    return null
}


function isEightWeeksInThePast(isoDateStr: string): boolean {
    const inputDate = new Date(isoDateStr);
    let referenceDate = new Date()
    referenceDate.setDate(referenceDate.getDate() - 8*7)

    let referenceMilis = referenceDate.getTime()
    let inputMilis = inputDate.getTime()

    return inputMilis <= referenceMilis
}

function isEighteenYearsInThePast(isoDateStr: string): boolean {
    const inputDate = new Date(isoDateStr);
    let referenceDate = new Date()
    referenceDate.setFullYear(referenceDate.getFullYear() - 18)

    let referenceMilis = referenceDate.getTime()
    let inputMilis = inputDate.getTime()

    return inputMilis <= referenceMilis
}

function isTomorrowOrLater(isoDateStr: string): boolean {
    const inputDate = new Date(isoDateStr);
    let referenceDate = new Date()
    referenceDate.setDate(referenceDate.getDate() + 1)
    referenceDate.setHours(0, 0, 0, 0); // next day is at midnight, not 24 hrs later, so we set hours, minutes, seconds and miliseconds to 0

    let referenceMilis = referenceDate.getTime()
    let inputMilis = inputDate.getTime()

    console.warn('tomorrow input milis', inputMilis)
    console.warn('tomorrow reference milis', referenceMilis)

    return inputMilis >= referenceMilis
}

function isValidIsoDateString(isoDateStr: string): boolean {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (isoDateStr === undefined || isoDateStr === null || isoDateStr.match(regex) === null) {
      return false;
    }

    const date = new Date(isoDateStr);
    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }

    return date.toISOString().startsWith(isoDateStr)
  }

export function petBirthdayValidatorFn(control: AbstractControl): ValidationErrors | null {
    return isValidIsoDateString(control.value as string) && isEightWeeksInThePast(control.value as string) ? null: {forbiddenDate: {value: control.value}};
}

export function parentBirthdayValidatorFn(control: AbstractControl): ValidationErrors | null {
    return isValidIsoDateString(control.value as string) && isEighteenYearsInThePast(control.value as string) ? null: {forbiddenDate: {value: control.value}};
}

export function startDateValidatorFn(control: AbstractControl): ValidationErrors | null {
    return isValidIsoDateString(control.value as string) && isTomorrowOrLater(control.value as string) ? null: {forbiddenDate: {value: control.value}};
}

export function optionalStartDateValidatorFn(control: AbstractControl): ValidationErrors | null {
    return control.value ? startDateValidatorFn(control) : null
}

export function fetchQueryParameterFromWindowLocation(queryParameterName: string): string | null {
    let url = window.location.href
    const searchParams = new URLSearchParams(url.slice(url.indexOf('?')));
    console.warn('fetchQueryParameterFromWindowLocationIfPresent, search params:', searchParams)
    let queryParameterValue = searchParams.get(queryParameterName);
    return queryParameterValue
}

export function addQueryParameterIfPresent(existingParameters: URLSearchParams, newParamName: string): URLSearchParams {
    let newParamValue = fetchQueryParameterFromWindowLocation(newParamName)
    if (newParamValue !== undefined && newParamValue !== null && newParamValue !== '' ) {
        existingParameters.set(newParamName, newParamValue)
    }
    return existingParameters
}

export function createStandardQueryParamsForUrlPrefix(): URLSearchParams {
    let urlSearchParams = new URLSearchParams()
    urlSearchParams = addQueryParameterIfPresent(urlSearchParams, 'custom-css')
    urlSearchParams = addQueryParameterIfPresent(urlSearchParams, 'bc')
    urlSearchParams = addQueryParameterIfPresent(urlSearchParams, 'utm_source')
    urlSearchParams = addQueryParameterIfPresent(urlSearchParams, 'utm_medium')
    urlSearchParams = addQueryParameterIfPresent(urlSearchParams, 'code')
    return urlSearchParams

}

export function getUrlWithHashLocation(): string {
    let url = window.location.href
    return url.split('?')[0]
}


export function languageCodeFromLocaleId(lid: string): string {
    if (lid.startsWith('en')) {
      return 'en'
    } else if (lid.startsWith('fr')) {
      return 'fr'
    } else if (lid.startsWith('it')) {
      return 'it'
    }
    return 'de'
  }
