<div class="stepper-wrapper">
  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-1' | transloco" class="stepper-element hover-responsive active-responsive" (click)="step1()"
         [ngClass]="{ 'active-responsive-text-active': step === 1}"></div>
  </div>

  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-2' | transloco" class="stepper-element" (click)="step2()"
         [ngClass]="{'responsive-text-disabled': !step1Complete(), 'hover-responsive': step1Complete(), 'active-responsive': step1Complete(), 'active-responsive-text-active': step === 2 && step1Complete() }"></div>
  </div>

  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-3' | transloco" class="stepper-element" (click)="step3()"
         [ngClass]="{'responsive-text-disabled': !(step1Complete() && step2Complete()), 'hover-responsive': step1Complete() && step2Complete(), 'active-responsive': step1Complete() && step2Complete(), 'active-responsive-text-active': step === 3 && step1Complete() && step2Complete() }"></div>
  </div>

  <div class="stepper-element-container">
    <div [innerHtml]="'stepper-step-4' | transloco" class="stepper-element" (click)="step4()"
         [ngClass]="{'responsive-text-disabled': !(step1Complete() && step2Complete() && step3Complete()), 'hover-responsive': step1Complete() && step2Complete() && step3Complete(), 'active-responsive': step1Complete() && step2Complete() && step3Complete(), 'active-responsive-text-active': step === 4 && step1Complete() && step2Complete() && step3Complete() }"></div>
  </div>

</div>
