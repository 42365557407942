<!--
the h2 below can be made visible by setting the corresponding attribute to true if there is a maintenance window.
-->
<h2 *ngIf="false" style="color: red; padding: 30px;" [innerHtml]="'banner-warning' | transloco"></h2>
<div id="main-header">
    <h1 *ngIf="step == 1" [innerHtml]="'banner-header-1' | transloco"></h1>
    <h1 *ngIf="step == 2 && petNameValid()" [innerHtml]="'banner-header-2' | transloco : {petName  : petName }"></h1>
    <h1 *ngIf="step == 3" [innerHtml]="'banner-header-3' | transloco"></h1>
    <h1 *ngIf="step == 4 && petNameValid()" [innerHtml]="'banner-header-4' | transloco : {petName  : petName }"></h1>
    <h1 *ngIf="step != 1 && step != 2 && step != 3 && step != 4" [innerHtml]="'banner-header-fallback' | transloco"></h1>
</div>