import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AlertComponent } from '../alert/alert.component';
import { TranslocoService } from '@ngneat/transloco';


export interface AcceptAlertModel {
  partnerName?: string;
  flavor?: string;
}
@Component({
  selector: 'app-accept-alert',
  template: `
  <div class="modal-content-custom">
    <div class="modal-header-custom">
      <h3 [innerHtml]="'accept-modal-header' | transloco"></h3>
    </div>
    <div class="modal-body-custom">
      <p [innerHtml]="'accept-modal-body' | transloco : {partnerName : partnerName}"></p>
      <!--
      <div class="accept-alert-switch-div">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="accepted">
          <span class="slider round"></span>
        </label>
        <b *ngIf="!accepted" [innerHtml]="'accept-modal-no-consent' | transloco"></b>
        <b *ngIf="accepted" [innerHtml]="'accept-modal-consent' | transloco"></b>
      </div>
      -->

    </div>

    <div class="accept-modal-footer-custom">
      <button (click)="clickButton()">OK</button>
      <p class="oneline-explanation">
        {{'accept-modal-oneline' | transloco : {partnerName : partnerName} }} <a class="link-in-text" (click)="clickText()">{{'accept-modal-oneline-link' | transloco}}</a>
      </p>
    </div>
  </div>
  `,
  styleUrls: ['./accept-alert.component.scss']
})
export class AcceptAlertComponent extends SimpleModalComponent<AcceptAlertModel, boolean> implements AcceptAlertModel {
  partnerName: string | undefined;
  flavor: string | undefined;
  accepted: boolean = false

  constructor(
    private gtmService: GoogleTagManagerService,
    private simpleModalService: SimpleModalService,
    private translation: TranslocoService,
  ) {
    super()
  }


  ngOnInit(): void {
  }

  clickButton() {
    const gtmTag = {
      event: 'data_disclosure_submitted',
      value: 'yes',
      flavor: this.flavor,
    };
    this.gtmService.pushTag(gtmTag)

    // we set modal result on click on button,
    // then we can get modal result from caller code
    this.accepted = true
    this.result = this.accepted
    this.close()
  }

  clickText() {
    const gtmTag = {
      event: 'data_disclosure_submitted',
      value: 'no',
      flavor: this.flavor,
    };
    this.gtmService.pushTag(gtmTag)

    // we set modal result on click on button,
    // then we can get modal result from caller code
    this.accepted = false
    this.result = this.accepted
    this.close()
    this.showNoSharingConfirmation(this.showNoSharingConfirmationTitle, this.showNoSharingConfirmationMessage)
  }

  showNoSharingConfirmation(title: ()=>string, message: ()=>string) {
    this.simpleModalService.addModal(AlertComponent, {title: title(), message: message()}, {
      closeOnClickOutside: true,
      closeOnEscape: true
    });
  }

  showNoSharingConfirmationTitle: ()=> string = () => this.translation.translate('accept-alert-no-sharing-confirmation-title');
  showNoSharingConfirmationMessage: ()=> string = () => this.translation.translate('accept-alert-no-sharing-confirmation-message');
}
