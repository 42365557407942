<div class="flex-wrapper">

    <div class="sub-page-root-closing">






        <h3 [innerHtml]="'parent-info-pet-parent-header' | transloco" class="h3-closing-screen"></h3>
        <form id="parent-info-form" [formGroup]="parentInfoForm">

            <h4 [innerHtml]="'parent-info-pet-parent-title-header' | transloco"></h4>
            <div class="choice-wrapper-short-2">
                <label for="mr">
                    <input id="mr" class="form-control" value="m" type="radio"
                        (click)="track('parent-title', 'selector', 'Mr.')" formControlName="parentGender">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('ms')" class="choice-div-short-pet">
                        <p [innerHtml]="'parent-info-pet-parent-title-mr-value' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
                <label for="ms">
                    <input id="ms" class="form-control" value="f" type="radio"
                        (click)="track('parent-title', 'selector', 'Ms.')" formControlName="parentGender">
                    <div [class.submitted-incomplete]="submittedIncomplete"
                        [class.sibling-selected]="isSelectedById('mr')" class="choice-div-short-pet">
                        <p [innerHtml]="'parent-info-pet-parent-title-ms-value' | transloco" class="center-center-p bold"></p>
                    </div>
                </label>
            </div>


            <h4 [innerHtml]="'parent-info-pet-parent-firstname-header' | transloco"></h4>

            <div class="text-input-wrapper">
                <input (ngModelChange)="firstName=$event" [class.submitted-incomplete]="submittedIncomplete" id="first-name"
                    class="form-control text-input" type="text" formControlName="firstName" (blur)="track('parent-firstname', 'text-input', '*****')">
            </div>

            <h4 [innerHtml]="'parent-info-pet-parent-lastname-header' | transloco"></h4>
            <div class="text-input-wrapper">
                <input (ngModelChange)="lastName=$event" [class.submitted-incomplete]="submittedIncomplete" id="last-name"
                    class="form-control text-input" type="text" formControlName="lastName" (blur)="track('parent-lastname', 'text-input', '*****')">
            </div>


            <h4 [innerHtml]="'parent-info-pet-parent-birthdate-header' | transloco"></h4>
            <div class="text-input-wrapper">

                <input (ngModelChange)="parentBirthdate=$event" [class.submitted-incomplete]="submittedIncomplete" [minDate]="{year: 1900, month: 1, day: 1}" [maxDate]="ngbDateEighteenYearsInThePast()" outsideDays="hidden"
                [weekdays]="false" id="parent-birthdate" class="form-control text-input" formControlName="parentBirthdate" [placeholder]="'datepicker-placeholder' | transloco"
                (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker" (keydown.enter)="blur($event.target)" (blur)="track('parent-birthdate', 'datepicker', '*****')">
                <p [innerHtml]="'pclosing-pet-parent-birthdate-explanation' | transloco" class="oneline-explanation"></p>

            </div>


            <h4 [innerHtml]="'parent-info-pet-parent-address-header' | transloco"></h4>
            <div class="text-input-wrapper-asymmetric-2-first-long">
                <div class="text-input-wrapper-with-header">
                    <p [innerHtml]="'parent-info-pet-parent-street-header' | transloco"></p>
                    <input [class.submitted-incomplete]="submittedIncomplete" id="street"
                        class="form-control text-input" type="text" formControlName="street" (blur)="track('parent-street', 'text-input', '*****')">
                </div>
                <div class="text-input-wrapper-with-header">
                    <p [innerHtml]="'parent-info-pet-parent-number-header' | transloco"></p>
                    <input [class.submitted-incomplete]="submittedIncomplete" id="street-number"
                        class="form-control text-input" type="text" formControlName="number" (blur)="track('parent-street-number', 'text-input', '*****')">
                </div>
            </div>

            <div class="text-input-wrapper-asymmetric-2-first-short">
                <div class="text-input-wrapper-with-header">
                    <p [innerHtml]="'parent-info-pet-parent-zip-header' | transloco"></p>
                    <input [class.submitted-incomplete]="submittedIncomplete" id="zip"
                        class="form-control text-input" type="text" formControlName="zip" (input)="setCityIfFound($event)" (blur)="track('parent-zip', 'text-input', '*****')">
                </div>
                <div class="text-input-wrapper-with-header">
                    <p [innerHtml]="'parent-info-pet-parent-place-header' | transloco"></p>
                    <input [class.submitted-incomplete]="submittedIncomplete" id="city"
                        class="form-control text-input" type="text" formControlName="city" (blur)="track('parent-city', 'text-input', '*****')">
                </div>
            </div>
            <p *ngIf="submittedIncomplete" class="oneline-explanation red-text" [innerHtml]="'parent-info-zip-city-error' | transloco"></p>


            <h4 [innerHtml]="'parent-info-pet-parent-phone-header' | transloco"></h4>
            <div class="text-input-wrapper">
                <input [class.submitted-incomplete]="submittedIncomplete" id="phone-number"
                    class="form-control text-input" type="text" formControlName="phoneNumber" placeholder="+41 xx xxx xx xx" (blur)="track('parent-phone-number', 'text-input', '*****')">
            </div>

            <h4 [innerHtml]="'parent-info-pet-parent-email-header' | transloco"></h4>
            <div class="text-input-wrapper">
                <input [class.submitted-incomplete]="submittedIncomplete" id="email"
                    class="form-control text-input" type="text" formControlName="email" (blur)="track('parent-email', 'text-input', '*****')">
            </div>


        </form>
    </div>

    <footer *ngIf="this.getCurrentTotal() !== undefined" class="closing-bottom">

        <div class="closing-bottom-content">
            <div class="total-amount-div-with-button">
                <h4 *ngIf="this.isMonthlyPayment" [innerHtml]="('parent-info-total-amount-text-monthly' | transloco : {displayPremiumMonthlygetCurrentTotalnumberfr : displayPremiumMonthly(getCurrentTotal()) | number:'1.2-2'}) + ' ' + (this.pricePercentageReduction !== undefined ? ('percentage-pricing-reduction-text' | transloco : {pricePercentageReduction : this.pricePercentageReduction}) : '')"></h4>
                <h4 *ngIf="!this.isMonthlyPayment" [innerHtml]="('parent-info-total-amount-text-yearly' | transloco : {displayPremiumYearlygetCurrentTotalnumberfr : displayPremiumYearly(getCurrentTotal()) | number:'1.2-2'}) + ' ' + (this.pricePercentageReduction !== undefined ? ('percentage-pricing-reduction-text' | transloco : {pricePercentageReduction : this.pricePercentageReduction}) : '')"></h4>
                <!--
                    <h4 *ngIf="this.isQuarterlyPayment()" [innerHtml]="'parent-info-total-amount-text-quarterly' | transloco"></b> <span class="big-font-span">CHF {{ this.displayPremiumQuarterly(this.getCurrentTotal()) | number:'1.2-2':'fr'}}</span> per quarter</h4>
                    <h4 *ngIf="!this.isQuarterlyPayment()" [innerHtml]="'parent-info-total-amount-text-yearly' | transloco"></h4>
                -->

                <!-- Dummy for formatting -->
                <!-- <p class="oneline-explanation"></p> -->
                <div class="marketing-consent-switch">
                    <label class="switch">
                        <input id="newsletter-consent-switch" type="checkbox" (click)="this.clickNewsletterConsent()" [(ngModel)]="newsletterConsent">
                        <span class="slider round slider-color-inverted"></span>
                    </label>
                    <!--
                        <p class="marketing-consent-explanation">Ich würde gerne Calingo's allmonatliche, von einer Tierärztin zusammengestellten Gesundheitstipps sowie Hinweise zu der optimalen Erziehung meiner Fellnase erhalten.</p>
                    -->
                    <p class="marketing-consent-explanation send-offer-in-cc-oneline">{{'newsletter-consent' | transloco }}</p>
                </div>


            </div>

            <div class="button-wrapper-in-footer">

                <div class="two-buttons-wrapper-dynamic-direction">
                    <div class="send-offer-link-button-wrapper">
                        <button [innerHtml]="'parent-info-submit-button-text' | transloco" (click)="onSubmit()" form="parent-info-form"></button>
                    </div>
                </div>
                <p [innerHtml]="'coverage-info-submit-button-error-text' | transloco" *ngIf="submittedIncomplete" class="oneline-explanation red-text"></p>
            </div>

        </div>

    </footer>

</div>
