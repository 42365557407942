import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StateService } from '../state.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: [ './stepper.component.scss']
})
export class StepperComponent implements OnInit {

  constructor(
    private router: Router,
    private stateService: StateService,

  ) { }

  step1Complete(): boolean {
    return this.stateService.step1Complete()
  }
  step2Complete(): boolean {
    return this.stateService.step2Complete()
  }
  step3Complete(): boolean {
    return this.stateService.step3Complete()
  }

  step: number = -1 // -1 is the initial value, means no step is active


  ngOnInit(): void {
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        if (value.url.startsWith('/pet-info')) {
          this.step = 1
          return;
        }
        if (value.url.startsWith('/coverage-info')) {
          this.step = 2
          return;
        }
        if (value.url.startsWith('/parent-info')) {
          this.step = 3
          return;
        }
        if (value.url.startsWith('/closing')) {
          this.step = 4
          return;
        }
        this.step = -1 // if we select a route that is not part of the steps, we want to clear the step state
      }
    });
  }

  step1(): void {
    console.warn("step 1")
    this.router.navigate(['/pet-info'], { queryParamsHandling: 'merge' });
  }

  step2(): void {
    console.warn("step 2")
    if (this.stateService.step1Complete()) {
      this.router.navigate(['/coverage-info'], { queryParamsHandling: 'merge' });
    }
  }

  step3(): void {
    console.warn("step 3")
    if (this.stateService.step1Complete() && this.stateService.step2Complete()) {
      this.router.navigate(['/parent-info'], { queryParamsHandling: 'merge' });
    }
  }

  step4(): void {
    console.warn("step 4")
    if (this.stateService.step1Complete() && this.stateService.step2Complete() && this.stateService.step3Complete()) {
      this.router.navigate(['/closing'], { queryParamsHandling: 'merge' });
    }
  }

}
