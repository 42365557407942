import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StateService } from '../state.service';

@Component({
  selector: 'app-heroes',
  templateUrl: './heroes.component.html',
  styleUrls: ['./heroes.component.scss']
})
export class HeroesComponent implements OnInit {

  constructor(
    private router: Router,
    private stateService: StateService,
  ) { }

  step: number = -1
  petName: string|undefined

  ngOnInit(): void {
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        if (value.url.startsWith('/pet-info')) {
          this.step = 1
          return;
        }
        if (value.url.startsWith('/coverage-info')) {
          this.step = 2
          return;
        }
        if (value.url.startsWith('/parent-info')) {
          this.step = 3
          return;
        }
        if (value.url.startsWith('/closing')) {
          this.step = 4
          return;
        }
        this.step = -1 // if we select a route that is not part of the steps, we want to clear the step state
      }
    });
    this.stateService.getState().subscribe(
      value => {
        this.petName = value.petName
      }
    )
  }

  petNameValid(): boolean {
    return this.petName != undefined && this.petName != null && this.petName != ''
  }
}
