import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { StateService } from '../state.service';
import { State } from '../state';
import dogBreedsList from '../../assets/dog_breeds.json';
import catBreedsList from '../../assets/cat_breeds.json';
import dogBreedsListEN from '../../assets/dog_breeds_en.json';
import catBreedsListEN from '../../assets/cat_breeds_en.json';
import dogBreedsListFR from '../../assets/dog_breeds_fr.json';
import catBreedsListFR from '../../assets/cat_breeds_fr.json';
import dogBreedsListIT from '../../assets/dog_breeds_it.json';
import catBreedsListIT from '../../assets/cat_breeds_it.json';
import { petBirthdayValidatorFn } from '../utils';

// import { SegmentService } from 'ngx-segment-analytics';
// import { GoogleAnalyticsService } from 'ngx-google-analytics';
// import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import sha256 from 'crypto-js';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../http-service.service';
import { DocumentsService } from '../documents.service';
import { LoadingScreenService } from '../loading-screen.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../alert/alert.component';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-pet-info',
  templateUrl: './pet-info.component.html',
  styleUrls: [ './pet-info.component.scss']
})
export class PetInfoComponent implements OnInit {

  constructor(
    private stateService: StateService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
    // private segment: SegmentService,
    private router: Router,
    // private gaService: GoogleAnalyticsService,
    private httpService: HttpService,
    private documentsService: DocumentsService,
    private loadingScreenService: LoadingScreenService,
    private gtmService: GoogleTagManagerService,
    private simpleModalService: SimpleModalService,
    private translation: TranslocoService
  ) { }

  ngOnInit(): void {
    // this.segment.page('Pet info', {path: '/pet-info'})
    // this.gaService.pageView('/pet-info', 'Pet info')
    const gtmTag = {
      event: 'page',
      pageName: 'pet-info',
      flavor: this.stateService.getSnapshot().flavor,
    };
    this.gtmService.pushTag(gtmTag)

    this.stateService.getState().subscribe(
      value =>
        {
          console.warn("state: ", value)
          if (value.petName !== undefined) {
            this.petName = value.petName
          }
          if (value.petName) {
            this.petInfoForm.get('petName')?.setValue(value.petName)
          }
          if (value.species) {
            this.petInfoForm.get('species')?.setValue(value.species)
          }
          if (value.petGender) {
            this.petInfoForm.get('petGender')?.setValue(value.petGender)
          }
          if (value.breed) {
            this.petInfoForm.get('breed')?.setValue(value.breed)
          }
          if (value.dogBreed) {
            this.petInfoForm.get('dogBreed')?.setValue(value.dogBreed)
          }
          if (value.catBreed) {
            this.petInfoForm.get('catBreed')?.setValue(value.catBreed)
          }
          if (this.petInfoForm.get('tallDog')?.value === undefined || this.petInfoForm.get('tallDog')?.value === null || this.petInfoForm.get('tallDog')?.value === '') {
              this.petInfoForm.get('tallDog')?.setValue(value.tallDog === true ? 'true' : (value.tallDog === false ? 'false' : undefined))
          }

          if (this.petInfoForm.get('housecat')?.value === undefined || this.petInfoForm.get('housecat')?.value === null || this.petInfoForm.get('housecat')?.value === '') {
            this.petInfoForm.get('housecat')?.setValue(value.housecat === true ? 'housecat' : (value.housecat === false ? 'outdoorCat' : undefined))
          }

          if (value.petBirthdate) {
            this.petInfoForm.get('petBirthdate')?.setValue(value.petBirthdate)
          }
          if (value.neutered !== undefined ) {
            this.petInfoForm.get('neutered')?.setValue(value.neutered?.toString())
          }
          if (value.firstName) {
            this.petInfoForm.get('firstName')?.setValue(value.firstName)
          }
          if (value.email) {
            this.petInfoForm.get('email')?.setValue(value.email)
          }
          if (value.showPreexistingConditions !== undefined ) {
            this.petInfoForm.get('showPreexistingConditions')?.setValue(value.showPreexistingConditions?.toString())
          }

        }



    )

    // reload breeds when language changes to show correct breed translation
    this.translation.langChanges$.subscribe(lang => {
      this.dogBreedOptions = this.fetchDogBreedList()
      this.catBreedOptions = this.fetchCatBreedList()
    })


    this.petInfoForm.get('species')?.valueChanges.subscribe(value => {
      this.updateConditionalValidators()
    })

    this.petInfoForm.get('breed')?.valueChanges.subscribe(value => {
      this.updateConditionalValidators()
    })

    this.stateService.isBrokerFlowObs.subscribe(
      value => {
        this.isBrokerFlow = value
      }
    )

  }

  hideSendOfferButton(): boolean {
    return this.stateService.getWhitelabellingUIControllerSnapshot().hideReentryLinkOfferButton === true
  }

  dogBreedOptions = this.fetchDogBreedList()
  catBreedOptions = this.fetchCatBreedList()

  fetchDogBreedList() {
    if (this.stateService.getCurrentLocaleId() === 'en') {
      return dogBreedsListEN
    } else if (this.stateService.getCurrentLocaleId() === 'fr') {
      return dogBreedsListFR
    } else if (this.stateService.getCurrentLocaleId() === 'it') {
      return dogBreedsListIT
    } else {
      return dogBreedsList
    }
  }

  fetchCatBreedList() {
    if (this.stateService.getCurrentLocaleId() === 'en') {
      return catBreedsListEN
    } else if (this.stateService.getCurrentLocaleId() === 'fr') {
      return catBreedsListFR
    } else if (this.stateService.getCurrentLocaleId() === 'it') {
      return catBreedsListIT
    } else {
      return catBreedsList
    }  }

  breedFilterFunction(term: string, item: Option): boolean {
    let termLc = term.toLowerCase();
    return item.name.toLowerCase().indexOf(termLc) > -1 || item.id === 'andere'
  }

  petName: string = ''
  selectedDogBreed: string = ''
  selectedCatBreed: string = ''
  firstName: string = ''
  email: string = ''
  petBirthdate: string = ''

  submittedIncomplete = false
  isBrokerFlow: boolean = false
  sendOfferCC: boolean = false

  ngbDateEightWeeksInThePast(): NgbDateStruct {
    let tmpDate = new Date()
    tmpDate.setDate(tmpDate.getDate() - 8*7 - 1) // go 1 day further to not show a date which will be rejected
    let ngbDate = { day: tmpDate.getDate(), month: tmpDate.getMonth() + 1, year: tmpDate.getFullYear()}
    return ngbDate
  }

  petNameDefined(): boolean {
    return this.petName !== undefined && this.petName !== '' && this.petName !== null
  }


  ngSelectPlaceholder: () => string = () => this.translation.translate('ng-select-placeholder')


  petInfoForm = new FormGroup({
    petName: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    species: new FormControl('', [Validators.required]),
    petGender: new FormControl('', [Validators.required]),
    breed: new FormControl('', [Validators.required]),
    dogBreed: new FormControl(null), // Validation is dynamically enabled, based on other values, do not add validations here, they will be cleared
    catBreed: new FormControl(null), // Validation is dynamically enabled, based on other values, do not add validations here, they will be cleared
    tallDog: new FormControl(''), // Validation is dynamically enabled, based on other values, do not add validations here, they will be cleared
    housecat: new FormControl(''), // Validation is dynamically enabled, based on other values, do not add validations here, they will be cleared
    petBirthdate: new FormControl('', [Validators.required, petBirthdayValidatorFn]),
    neutered: new FormControl('', [Validators.required]),
    firstName: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    email: new FormControl('', {updateOn: 'blur', validators: [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]}),
    showPreexistingConditions: new FormControl('', [Validators.required])
  });


  updateConditionalValidators(): void {
    if (this.showDogBreedSelector()) {
      this.petInfoForm.get('dogBreed')?.setValidators([Validators.required])
    } else {
      this.petInfoForm.get('dogBreed')?.clearValidators()
    }
    this.petInfoForm.get('dogBreed')?.updateValueAndValidity({onlySelf: true})

    if (this.showCatBreedSelector()) {
      this.petInfoForm.get('catBreed')?.setValidators([Validators.required])
    } else {
      this.petInfoForm.get('catBreed')?.clearValidators()
    }
    this.petInfoForm.get('catBreed')?.updateValueAndValidity({onlySelf: true})

    if (this.showDogHeightOptions()) {
      this.petInfoForm.get('tallDog')?.setValidators([Validators.required])
    } else {
      this.petInfoForm.get('tallDog')?.clearValidators()
    }
    this.petInfoForm.get('tallDog')?.updateValueAndValidity({onlySelf: true})

    if (this.showHousecatSelector()) {
      this.petInfoForm.get('housecat')?.setValidators([Validators.required])
    } else {
      this.petInfoForm.get('housecat')?.clearValidators()
    }
    this.petInfoForm.get('housecat')?.updateValueAndValidity({onlySelf: true})


  }

  showDogBreedSelector(): boolean {
    return (this.petInfoForm.controls['species'].value == 'dog' && (this.petInfoForm.controls['breed'].value == 'cross' || this.petInfoForm.controls['breed'].value == 'pedigree'))
  }

  showDogHeightOptions(): boolean {
    return this.petInfoForm.controls['species'].value == 'dog' && this.petInfoForm.controls['breed'].value == 'mixed' || this.selectedDogBreed === 'andere'
  }

  showCatBreedSelector(): boolean {
    return (this.petInfoForm.controls['species'].value == 'cat' && (this.petInfoForm.controls['breed'].value == 'cross' || this.petInfoForm.controls['breed'].value == 'pedigree'))
  }

  showHousecatSelector(): boolean {
    return this.petInfoForm.controls['species'].value == 'cat'
  }

  // see https://medium.com/javascript-everyday/how-to-scroll-to-first-invalid-control-once-a-form-has-been-submitted-eb47d9fbc6e
  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.document.querySelector(
      "form .ng-invalid"
    );

    if (firstInvalidControl !== null && firstInvalidControl !== undefined && (firstInvalidControl as HTMLElement).focus !== undefined) {
      console.warn("first invalid element: " + firstInvalidControl?.id);
      // small hack: ng-select cant be focused for some reason, just focus on the element above it and be done with it.
      if (firstInvalidControl?.id === 'cat-breeds' || firstInvalidControl?.id === 'dog-breeds') {
        setTimeout(() => (this.document.getElementById('pedigree') as HTMLElement).focus(), 10)
        return
      }
      setTimeout(() => (firstInvalidControl as HTMLElement).focus(), 10)
    }
  }

  findInvalidControls(): string[] {
    const invalid = [];
    const controls = this.petInfoForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }

  isSelectedById(id: string): boolean {
    let elem = this.document.getElementById(id)
    if (elem && (elem as HTMLInputElement).checked !== undefined ) {
      return (elem as HTMLInputElement).checked
    }
    return false
  }

  track(itemText: string, formItem: string, value: string, location?: string): void {
    if (value !== null && value !== undefined && value !== '') {
      // this.segment.track('Pet Info Updated', {path: '/pet-info', item_text: itemText, form_item: formItem, value: value, location: location || null})
      /*
      this.gaService.gtag('event', 'pet_info_updated', {
        'item_text': itemText,
        'form_item': formItem,
        'value': value,
        'location': location || null
      })
      */
    }
  }

  blur(target: EventTarget | null) {
    if (target !== null) {
      (target as HTMLInputElement).blur()
    }
  }

  getCurrentLocaleId(): string {
    return this.stateService.getCurrentLocaleId()
  }

  getPrivacyPolicyPath(): string {
    return this.documentsService.getCurrentLanguagePrivacyPolicyPath()
  }

  showAlert(title: ()=>string, message: ()=>string) {
    this.simpleModalService.addModal(AlertComponent, {title: title(), message: message()}, {
      closeOnClickOutside: true,
      closeOnEscape: true
    });
  }


  preexistingConditionsInfoTitle: () => string = () => this.translation.translate('pet-info-infobutton-title-preexisting-conditions-coverage')
  preexistingConditionsInfoMessage: () => string = () => this.translation.translate('pet-info-infobutton-message-preexisting-conditions-coverage')

  petBirthdateInfoTitle: () => string = () => this.translation.translate('pet-info-infobutton-title-pet-birthdate-explanation')
  petBirthdateInfoMessage: () => string = () => this.translation.translate('pet-info-pet-birthdate-explanation')

  
  onSubmit(isBrokerFlowButton: boolean) {

    console.warn('local variable pet birthdate', this.petBirthdate)
    // TODO: Use EventEmitter with form value
    if (this.petInfoForm.invalid) {
      console.warn("form invalid")
      this.submittedIncomplete = true
      this.scrollToFirstInvalidControl()
      // this.segment.track('Pet Info Submission Failed', {invalid_fields: this.findInvalidControls(), path: '/pet-info'})
      // this.gaService.gtag('event', 'pet_info_submission_failed', {invalid_fields: this.findInvalidControls(), path: '/pet-info'})
      return
    }

    if (!this.stateService.validateBrokerEmailPresent()) {
      console.warn('invalid broker input')
      // this.stateService.setLocaState(state)
      return
    }

    this.loadingScreenService.showLoadingScreenPreSale(this.petInfoForm.get('species')?.value)

    /*
    this.segment.track('Pet Info Submitted',{
      path: '/pet-info',
      pet_name: this.petInfoForm.get('petName')?.value,
      pet_species: this.petInfoForm.get('species')?.value,
      pet_gender: this.petInfoForm.get('petGender')?.value,
      pet_breed: this.petInfoForm.get('breed')?.value,
      dog_breed: this.petInfoForm.get('dogBreed')?.value,
      cat_breed: this.petInfoForm.get('catBreed')?.value,
      dog_tall: this.petInfoForm.get('tallDog')?.value,
      housecat: this.petInfoForm.get('housecat')?.value,
      pet_birthdate: this.petInfoForm.get('petBirthdate')?.value,
      pet_neutered: this.petInfoForm.get('neutered')?.value,
      parent_firstname: sha256.SHA256(this.petInfoForm.get('firstName')?.value).toString(),
    });
    */
   /*
    this.gaService.gtag('event', 'pet_info_submitted', {
      'path': '/pet-info',
      'pet_name': this.petInfoForm.get('petName')?.value,
      'pet_species': this.petInfoForm.get('species')?.value,
      'pet_gender': this.petInfoForm.get('petGender')?.value,
      'pet_breed': this.petInfoForm.get('breed')?.value,
      'dog_breed': this.petInfoForm.get('dogBreed')?.value,
      'cat_breed': this.petInfoForm.get('catBreed')?.value,
      'dog_tall': this.petInfoForm.get('tallDog')?.value,
      'housecat': this.petInfoForm.get('housecat')?.value,
      'pet_birthdate': this.petInfoForm.get('petBirthdate')?.value,
      'pet_neutered': this.petInfoForm.get('neutered')?.value,
      'parent_firstname': sha256.SHA256(this.petInfoForm.get('firstName')?.value).toString(),
    })
    */
    const gtmTag = {
      event: 'pet_info_submitted',
      pageName: 'pet-info',
      pet_name: this.petInfoForm.get('petName')?.value,
      pet_species: this.petInfoForm.get('species')?.value,
      pet_gender: this.petInfoForm.get('petGender')?.value,
      pet_breed: this.petInfoForm.get('breed')?.value,
      dog_breed: this.petInfoForm.get('dogBreed')?.value,
      cat_breed: this.petInfoForm.get('catBreed')?.value,
      dog_tall: this.petInfoForm.get('tallDog')?.value,
      housecat: this.petInfoForm.get('housecat')?.value,
      pet_birthdate: this.petInfoForm.get('petBirthdate')?.value,
      pet_neutered: this.petInfoForm.get('neutered')?.value,
      flavor: this.stateService.getSnapshot().flavor,
    };
    this.gtmService.pushTag(gtmTag)
    /*
    this.segment.identify(sha256.SHA256(this.petInfoForm.get('email')?.value).toString(), {
      path: '/pet-info',
      pet_name: this.petInfoForm.get('petName')?.value,
      pet_species: this.petInfoForm.get('species')?.value,
      pet_gender: this.petInfoForm.get('petGender')?.value,
      pet_breed: this.petInfoForm.get('breed')?.value,
      dog_breed: this.petInfoForm.get('dogBreed')?.value,
      cat_breed: this.petInfoForm.get('catBreed')?.value,
      dog_tall: this.petInfoForm.get('tallDog')?.value,
      housecat: this.petInfoForm.get('housecat')?.value,
      pet_birthdate: this.petInfoForm.get('petBirthdate')?.value,
      pet_neutered: this.petInfoForm.get('neutered')?.value,
      parent_firstname: sha256.SHA256(this.petInfoForm.get('firstName')?.value).toString(),
    })
    */
    // TODO: Maybe there is a corresponding call for GA4, Patrick will check

    this.submittedIncomplete = false

    console.warn(this.petInfoForm.value);

    let state = this.petInfoForm.value as State
    // silly radio buttons return strings, we have to handle the type conversion here. Validation is done va FormControl, so this basic conversion should do the trick.
    state.neutered = this.petInfoForm.get('neutered')?.value === 'true'
    state.showPreexistingConditions = this.petInfoForm.get('showPreexistingConditions')?.value === 'true'
    if (this.petInfoForm.get('tallDog')?.value !== "") {
      state.tallDog = this.petInfoForm.get('tallDog')?.value === 'true'
    }
    if (this.petInfoForm.get('housecat')?.value !== "") {
      state.housecat = this.petInfoForm.get('housecat')?.value === 'housecat'
    }

    if (state.species === 'dog' && (state.breed === 'mixed' || state.dogBreed === 'andere' || state.dogBreed === undefined || state.dogBreed === null || state.dogBreed === '')) {
      // checking for values explicitly because of undefined
      if (state.tallDog === true) {
        state.dogBreed = 'mischling_gross'
      } else if (state.tallDog === false) {
        state.dogBreed = 'mischling_klein'
      }
    }

    if (state.species === 'cat' && (state.breed === 'mixed' || state.catBreed === 'andere' || state.catBreed === undefined || state.catBreed === null || state.catBreed === '')) {
      // checking for values explicitly because of undefined
      state.catBreed = 'mischlingskatze'
    }

    // Uppercase first letter of petName in case the user forgot, as this looks better in terms of personalization
    if (state.petName !== undefined) {
      state.petName = state.petName?.charAt(0).toUpperCase() + state.petName?.slice(1)
    }

    // Uppercase first letter of firstName in case the user forgot, as this looks better in terms of personalization
    if (state.firstName !== undefined) {
      state.firstName = state.firstName?.charAt(0).toUpperCase() + state.firstName?.slice(1)
    }

    if(state.showPreexistingConditions === undefined || state.showPreexistingConditions === null) {
      state.showPreexistingConditions = true
    }

    if (isBrokerFlowButton) {
      this.stateService.setState(state).then(
          () => {
            let sessionId = this.stateService.getSnapshot().sessionId
            if (sessionId) {
              return this.httpService.sendOffer(sessionId, this.sendOfferCC)
            }
            return null
          }
        ).then(() => {
          let currentLocale = this.stateService.getCurrentLocaleId()
          let localeSubdomain = ''

          if (currentLocale === 'en-US') {
            localeSubdomain = 'en.'
          } else if (currentLocale === 'fr') {
            localeSubdomain = 'fr.'
          } else if (currentLocale === 'it') {
            localeSubdomain = 'it.'
          }

          let state = this.stateService.getSnapshot()
          let species = state.species
          let bc = state.brokerCode
          let firstName = state.firstName
          let petName = state.petName
          let customCssCode = this.stateService.getSnapshot().customCssCompanyCode
          if (species !== undefined && species !== null && species !== '' && species === 'cat') {
            window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/cat' + '?bc=' + bc + '&firstName=' +  firstName + '&petName=' + petName + '&custom-css=' + customCssCode)
          } else {
            window.location.href = ('https://' + localeSubdomain + 'pet.calingo.ch/success/send-offer/dog' + '?bc=' + bc + '&firstName=' +  firstName + '&petName=' + petName + '&custom-css=' + customCssCode)
          }
        })
    } else {
      this.stateService.setState(state).then(() => this.router.navigate(['/coverage-info'], { queryParamsHandling: 'merge' })
      )
    }
  }

}
interface Option {name: string, id: string}

